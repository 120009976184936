import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HelpersService} from '../../shared/services/helpers.service';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HubCorporateService} from '../../shared/services/hub-corporate.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Corporate} from "../../shared/types/corporate";
import {NgbModal, NgbTabset} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-corporate-list',
  templateUrl: './corporate-list.component.html',
  styleUrls: ['./corporate-list.component.scss']
})
export class CorporateListComponent implements OnInit, OnDestroy {
  @ViewChild('tabs', { static: false }) tabset: NgbTabset;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  showLoader = false;
  isSearchInputFocused = false;

  // pagination
  page = 1;
  pageSize = 10;

  corporates: Corporate[] = [];
  filteredCorporates: Corporate[] = [];
  selectedCorporate: Corporate;

  constructor(public helpers: HelpersService,
              public fb: FormBuilder,
              private router: Router,
              private corporateService: HubCorporateService,
              private route: ActivatedRoute,
              private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getCorporates();
    this.parseUrl();
  }

  getCorporates() {
    this.corporateService.getAll()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((res: any) => {
        if (res.body) {
          this.corporates = this.filteredCorporates = res.body;
          let tab = this.route.snapshot.queryParamMap.get('tab');
          if (tab) {
            this.tabset.select(tab);
          }
        }
      }, err => console.log(err));
  }

  onSearchCorporate(value: string) {
    const result = this.corporates.filter(corporate => {
      const val = value.toLowerCase();
      return corporate.name.toLowerCase().includes(val);
    });
    this.filteredCorporates = value ? result : this.corporates;
  }

  openCorporateModal(content, size: any = 'lg', corporate: Corporate) {
    this.selectedCorporate = corporate;
    this.open(content, size);
  }

  open(content, size: any = 'lg') {
    this.modalService.open(content, {
      size
    })
      .result.then((result) => {
    }, (reason) => {
    });
  }

  parseUrl() {
    let params = this.route.snapshot.queryParams;
    this.page = parseInt(params.page) || 1;
    this.pageSize = parseInt(params.pageSize) || 15;
  }

  onPageChange() {
    let params = {};
    params['page'] = this.page;
    params['pageSize'] = this.pageSize;
    this.router.navigate([], {queryParams: params});
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
