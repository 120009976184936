import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { LocalStorageService } from './local-storage.service';
import {environment} from "../../../environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { BehaviorSubject, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class UmbrellaService {
  private baseUrl = `${environment.ndcApiEndpointV2}agent/umbrella/`;
  private umbrellaBaseUrl = '';

  private isUmbrellaAuth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUmbrellaAuth$: Observable<boolean> = this.isUmbrellaAuth.asObservable();

  constructor(
    public http: HttpClient,
    private ls: LocalStorageService,
  ) { }


  checkUmbrellaIsAuth() {
    return this.getCompany('check umbrella on auth').pipe(
      catchError(err => of(false)),
      tap( res => {
        this.isUmbrellaAuth.next(!(res && res.error && res.error === 'invalid_token'));
      })
    );
  }

  /**
   * first step of authorization in umbrella
   * redirect to hurricane.umbrellanet.ch
   */
  redirectToUmbrellaLogin(): void {
    const origin = location.origin;
    const hrefLink = location.href;
    this.ls.setHrefLink(hrefLink);
    window.location.href = `${this.umbrellaBaseUrl}/oauth/authorize?response_type=code&client_id=${this.ls.clientId}&redirect_uri=${origin}/umbrella-auth`;
  }

  /**
   * get Umbrella token
   * @param the_auth_code
   * return umbrellaToken
   */
  getToken(the_auth_code: string) {
    const origin = location.origin;
    return this.http.get(`${this.baseUrl}tokens?code=${the_auth_code}&origin=${origin}/umbrella-auth`, {
      observe: 'response'
    }).pipe(tap(res => this.handleTokens(res)));

  }

  /**
   * refresh Umbrella token
   * return refresh_token
   */
  getRefreshToken() {
    const refresh_token = this.ls.refreshToken;
    return this.http.get(`${this.baseUrl}refresh-token?token=${refresh_token}`, {observe: 'response'}).pipe(tap(res => this.handleTokens(res)));
  }

  /**
   * get Umbrella Company
   * @param searchStr
   */
  getCompany(searchStr): Observable<any> {
    let uToken = this.ls.umbrellaToken;
    if (uToken) {
      const headers = new HttpHeaders();
      headers.append('Authorization', uToken);
      return this.http.get(`${this.baseUrl}companies?token=${uToken}&q=${searchStr}`);
    }
    return of(null);
  }

  /**
   * get Umbrella Company Data
   * @param id
   */
  getCompanyData(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}company?id=${id}`);
  }

  /**
   * Get all Travellers in company 'c=${companyId}'
   * @param searchStr
   */
  getTravelers(searchStr: string, target: string = null) {
    const uToken = this.ls.umbrellaToken;
    const companyId = JSON.parse(this.ls.UmbrellaCompany).id;
    if (uToken) {
      const headers = new HttpHeaders();
      const targetValue: string = target === "email" ? 'EMAIL' : 'DEFAULT';
      headers.append('Authorization', uToken);
      return this.http.get(`${this.baseUrl}travelers?token=${uToken}&q=${searchStr}&c=${companyId}&scope=${targetValue}`);
    }
    return of(null);
  }

  /**
   * get Umbrella Traveler Profile
   * @param trevellerID
   */
  getTravelerProfile(trevellerID: string) {
    const uToken = this.ls.umbrellaToken;
    if (uToken) {
      const headers = new HttpHeaders();
      headers.append('Authorization', uToken);
      return this.http.get(`${this.baseUrl}traveler-profile?token=${uToken}&id=${trevellerID}`);
    }
  }

  /**
   * set Umbrella token & refresh
   * @param res
   */
  private handleTokens(res) {
    if (res.body.access_token && res.body.refresh_token) {
      this.ls.setUmbrellaToken(`Bearer ${res.body.access_token}`);
      this.ls.setUmbrellaRefreshToken(res.body.refresh_token);
    }
  }

  getBaseUrl(): Observable<string> {
    if (!!this.umbrellaBaseUrl) {
      return of(this.umbrellaBaseUrl);
    }
    return this.http.get(`${this.baseUrl}base-url`)
      .pipe(
        filter((res: any) => res.status === 'OK' && !!res.meta),
        tap((res: any) => this.umbrellaBaseUrl = res.meta),
        map((res: any) => res.meta),
        catchError(error => {
            return of('Can\'t receive Umbrella Base Url, got error', error);
        })
      );
  }
}
