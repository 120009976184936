import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2, ViewChild,
} from "@angular/core";
import {ErrorAlert} from "../../models/error-alert";
import {NDCApiService} from "../../services/ndc-api.service";
import {HelpersService} from "../../services/helpers.service";
import {PaymentModuleService} from "../../services/payment-module.service";
import {convertPhoneIntoString} from "../../adapters/v1_2/adapters";
import { SeatMapService } from '../../services/seat-map.service';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit, OnDestroy {

  @Input() order;
  @Input() shoppingResponseID;
  @Input() reshopOfferID;
  @Input() services = [];
  @Input() seats = [];
  @Input() passengers = [];
  @Input() type: string;
  @Input() paymentError: ErrorAlert;
  @Output() emitInstallmentId = new EventEmitter<string>();
  @Output() emitStepBack = new EventEmitter<boolean>();

  @ViewChild('totalElement', { static: false }) totalElement!: ElementRef<HTMLSpanElement>;
  dynamicWidth = 'auto';

  modalContentElement: HTMLElement | null;
  paymentInfoLoader = false;
  paymentInfoError: ErrorAlert = new ErrorAlert();
  installmentOptions = [];

  seatsTotal: number;
  servicesTotal: number;
  consumerTax: any;
  consumerPrice: any;
  consumerFee: number;
  consumerSurcharge: number;
  consumerTotal: number;
  providerTotal: number;
  reIssueFareAndTaxDifference: number;
  consumerCurrency: string;
  providerCurrency: string;
  currencyMatch: boolean;


  constructor(private ndcApiService: NDCApiService,
              private helpers: HelpersService,
              private paymentService: PaymentModuleService,
              private seatMapService: SeatMapService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.modalContentElement = document.querySelector('.modal-content');
    if (this.modalContentElement) {
      this.renderer.addClass(this.modalContentElement, 'payment-form-modal-content');
    }
    this.sendPaymentInfo();
  }

  sendPaymentInfo() {
    const isNoneMethod = this.paymentService?.form?.get('method')?.value === 'none';

    if (!isNoneMethod) {
      this.paymentInfoLoader = true;
      const body: any = {
        payment: this.paymentService.getPaymentData(),
        type: this.type
      };
      if (this.shoppingResponseID) {
        body.shoppingResponseID = this.shoppingResponseID;
      } else {
        body.id = this.order.id;
      }
      if (this.reshopOfferID) {
        body.reshopOfferID = this.reshopOfferID;
      }
      if (body.payment && body.payment.phone) {
        body.payment.phone = convertPhoneIntoString(body.payment.phone);
      }

      const services = [
        ...this.helpers.mapServices(this.services),
        ...this.seatMapService.mapSeats(this.seatMapService.selectedSeats)
      ];

      if (services.length) {
        body.services = services;
      }

      this.ndcApiService.sendPaymentInfo(body)
        .then(res => {
          this.paymentInfoLoader = false;
          if (res) {
            this.installmentOptions = res.installmentOptions;
            this.paymentService.selectedInstallmentId = this.installmentOptions?.[0]?.installmentId;

            const price = res.price;
            if (price) {
              this.seatsTotal = res?.seatsPrice?.consumer?.total;
              this.servicesTotal = res?.servicesPrice?.consumer?.total;
              this.consumerPrice = price.consumer;
              this.consumerTax = price.consumer?.tax;
              this.consumerFee = price.consumer?.fee?.total;
              this.consumerSurcharge = price.consumer?.surcharge;
              this.consumerTotal = price.consumer?.total;
              this.providerTotal = price.provider?.total;
              this.reIssueFareAndTaxDifference = this.consumerPrice.base + (this.consumerTax?.total || 0);
              this.consumerCurrency = price.consumer?.currency;
              this.providerCurrency = price.provider?.currency;
              this.currencyMatch = this.consumerCurrency === this.providerCurrency;

              setTimeout(() => {
                this.calculateAndApplyDynamicWidth();
              });
            }
            if (this.type === 'pay_offer_on_ordercreate' || this.type === 'pay_ancillaries_on_orderchange') {
              this.seats = this.processSelectedSeats();
            }
          }
        }).catch(err => {
          this.paymentInfoLoader = false;
          this.paymentInfoError = this.helpers.getError(err);
      });
    } else {
      this.emitStepBack.emit(true);
    }
  }

  calculateAndApplyDynamicWidth(): void {
    if (this.consumerTotal && this.totalElement) {
      const elementWidth = this.totalElement.nativeElement.offsetWidth;
      this.dynamicWidth = `${elementWidth}px`;

      this.applyDynamicWidthToElements();
    }
  }

  applyDynamicWidthToElements(): void {
    const adjustableElements = document.querySelectorAll('.price-column');
    adjustableElements.forEach((element) => {
      (element as HTMLElement).style.minWidth = this.dynamicWidth;
    });
  }

  processSelectedSeats(): string[] {
    const seats: any[] = [];
    const selectedSeats = this.seatMapService.selectedSeats;

    if (selectedSeats && typeof selectedSeats === 'object') {
      for (const [seatKey, seatValue] of Object.entries(selectedSeats)) {
        if (seatValue && typeof seatValue === 'object') {
          const seatObjPairs = Object.entries(seatValue);
          for (const [seatObjKey] of seatObjPairs) {
            const seat = this.seatMapService.allSeats[seatKey]?.[seatObjKey];
            if (seat) {
              seats.push(seat);
            }
          }
        }
      }
    }

    return seats;
  }


  onInstallmentOptionChange(installmentId: string, isChecked: boolean) {
    this.paymentService.selectedInstallmentId = isChecked ? installmentId : '';
  }

  ngOnDestroy() {
    if (this.modalContentElement) {
      this.renderer.removeClass(this.modalContentElement, 'payment-form-modal-content');
    }
  }

}
