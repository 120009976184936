import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Location} from '@angular/common';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit {
  @Input() items;

  constructor(private router: Router,
              protected location: Location
  ) { }

  ngOnInit() {
  }

  go(url: string, queryParams: any) {
    this.router.navigate([url], {queryParams});
  }

}
