import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appInputValidate]'
})
export class InputValidateDirective  {

  @Input() set errorMessage(message: string) {
    if (message) {
      const container = this.renderer.createElement('div');
      container.innerHTML = message;
      const square = this.renderer.createElement('div');
      this.renderer.addClass(square, 'decorator');
      this.renderer.addClass(container, 'error-container');
      this.renderer.appendChild(container, square);
      this.renderer.appendChild(this.elementRef.nativeElement, container);
    } else {
      const containers = this.elementRef.nativeElement.querySelectorAll('.error-container');
      if (containers) {
        for (let elem of containers) {
          this.renderer.addClass(elem, 'remove');
          if (this.elementRef.nativeElement.contains(elem)) {
            this.renderer.removeChild(this.elementRef.nativeElement, elem);
          }
        }
      }
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }


}
