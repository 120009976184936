import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  HostListener,
  OnDestroy
} from '@angular/core';
import {fromEvent, Subscription} from "rxjs";
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { LocalStorageService } from '../../services/local-storage.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {DataTransferService} from "../../services/data-transfer.service";

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss']
})
export class InputDropdownComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() placeholder = "";
  @Input() noFocus = false;
  @Input() leftLoader = false;
  @Input() listData: any[] = [];
  @Input() target = '';
  @Input() isDisabled = false;
  @Input() inputValue = '';
  @Input() autocomplete = 'false';
  @Input() isValid: boolean;
  @Input() isInvalid: boolean;
  @Input() useNewDesign: boolean;
  @Output() emitSearchString = new EventEmitter();
  @Output() emitSelectedItem = new EventEmitter();
  @Output() emitSelectedItemText = new EventEmitter();
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef;
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  showInputLoader = false;
  showList = false;
  emptyResult = false;
  selectedIndex: number;
  listHeight = 200;
  itemListHeight = 35;

  private select_id;
  subscription: Subscription;

  @HostListener('document:click', ['$event']) clickout({target}) {
    if (target.className !== 'drop-down-list-item'
      && target.className !== 'drop-down-list'
      && !target?.classList.contains('drop-down-input')
      && target.className !== 'input-wp') {
      this.showList = false;
    }
  }
  constructor(
    private ls: LocalStorageService,
    private dataTransferService: DataTransferService
    ) { }

  ngOnInit() {
    this.select_id = Math.random().toString(36).substr(2, 9);
    this.selectedIndex = 0;
    this.subscription = this.dataTransferService.inputDropDown$.subscribe(data => {
      if ( this.select_id !== data ) {
        this.showList = false;
      }
    });
    // if ( this.ls.UmbrellaCompany && this.target === "Company" ) {
    //   this.inputValue = JSON.parse(this.ls.UmbrellaCompany).text;
    // }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    /**
     * stream from event 'send input value to server'
     */
    const streamSearch$ = fromEvent(this.searchInput.nativeElement, 'input');

    streamSearch$
    .pipe(
      map((e: any) => e.target.value),
      debounceTime(1000),
      distinctUntilChanged()
    )
    .subscribe(val => {
      this.showInputLoader = true;
      this.emitSearchString.emit(val);
    });
    if (this.searchInput.nativeElement && !this.noFocus) {
      setTimeout(() => this.searchInput.nativeElement.focus(), 50);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listData) {
      this.selectedIndex = 0;
      this.showInputLoader = false;
      this.setListContainerHeight(this.listData.length);
      if (!changes.listData.currentValue.length && !changes.listData.firstChange ) {
        this.showList = true;
        this.emptyResult = true;
      } else {
        this.showList = !!this.listData.length;
        this.emptyResult = !this.showList;
      }
    }
  }


  /**
   * on focus input
   * @param event
   */
  onFocusInput(event): void {
    if (this.listData.length) {
      this.showList = true;
    }
  }



  keyDownHandler(event) {
    switch (event.key) {
      case 'ArrowDown':
        this.onArrowDown();
        return;
      case 'ArrowUp':
        this.onArrowUp();
        return;
      case 'Enter':
        this.pressEnterKey();
        return;
    }
  }

  pressEnterKey() {
    let element: HTMLElement = document.getElementsByClassName('focus-background')[0] as HTMLElement;
    if (element !== undefined) {
      element.click();
    }
  }

  onArrowUp() {
    if (this.inputValue && this.selectedIndex === -1) { return; }
    if (!this.inputValue && this.selectedIndex === 0) { return; }
    if (!this.listData.length) {
      this.selectedIndex = -1;
      return;
    }
    this.selectedIndex--;
    if (this.listData.length && this.viewport.scrollToIndex) {
      this.viewport.scrollToIndex(this.selectedIndex);
    }
  }

  onArrowDown() {
    if (this.selectedIndex === this.listData.length - 1) { return; }
    this.selectedIndex++;
    if (!this.listData.length) {
      this.selectedIndex = -1;
    }
    if (this.listData.length && this.viewport.scrollToIndex) {
      this.viewport.scrollToIndex(this.selectedIndex);
    }
  }

  setListContainerHeight(countItems: number) {
    if (countItems === 0) { this.listHeight = 0; }
    if (countItems > 0 && countItems < 9) {
      this.listHeight = this.itemListHeight * countItems;
    }
    if (countItems >= 9) {
      this.listHeight = 315;
    }
  }

  toggleOptionList(event) {
    if (!event.altKey) {
      // this.showList = !this.showList;
      this.dataTransferService.inputDropDown$.next(this.select_id);
    }
  }

  onBlurInput(event) {

  }

  /**
   * on select item
   * @param item
   */
  onSelectItem(item): void {
    this.showList = false;
    this.inputValue = item ? item.text : "";
    this.emitSelectedItem.emit(item);
  }
}
