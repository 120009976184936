import {Directive, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges} from '@angular/core';
import moment from 'moment';

@Directive({
  selector: '[appGraphRange]'
})
export class GraphRangeDirective implements OnChanges {

  private maxItems = 40;
  private percentage = 90;
  private minBarHeightPX = 10;
  private maxPaymentTimeLimitSteps = 80;
  private oldChild: HTMLElement;
  @Input() modelData;
  @Input() modelDestination;
  @Output() onSelectedBar = new EventEmitter<any>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.modelData) {
      switch (this.modelDestination) {
        case 'price':
          return this.buildPriceGraph();
        case 'timeRange':
        case 'duration':
          return this.buildTimeRangeGraph();
        case 'paymentTimeLimit':
          return this.buildPaymentTimeLimitGraph();
      }
    }
  }

  private buildPriceGraph() {
    if (this.modelData.minPrice && this.modelData.maxPrice) {
      const step = (this.modelData.maxPrice - this.modelData.minPrice) / this.maxItems;
      const ranges = [];
      let lastValue = this.modelData.minPrice;
      for (let i = 0; i < this.maxItems; i++) {
        const temp = {
          min: i === 0 ? lastValue - 1 : lastValue,
          max: lastValue + step,
          prices: []
        };
        lastValue = lastValue + step;
        ranges[i] = temp;
      }
      ranges[ranges.length - 1].max += 1;

      this.modelData.all.map(price => {
        ranges.map(range => {
          if (price > range.min && price <= range.max) {
            range.prices.push(price);
          }
        });
      });

      const maxCount = Math.max.apply(Math, ranges.map(function (o) {
        return o.prices.length;
      }));

      if ((this.oldChild && this.elementRef.nativeElement) && this.elementRef.nativeElement.contains(this.oldChild)) {
        this.renderer.removeChild(this.elementRef.nativeElement, this.oldChild);
      }

      const graph: HTMLElement = this.renderer.createElement('ul');
      this.renderer.addClass(graph, 'graph-wp');

      ranges.map(range => {

        const li: HTMLElement = this.renderer.createElement('li');
        this.renderer.setStyle(li, 'height', `${!range.prices.length ? 0 : (range.prices.length / maxCount * this.percentage) < this.minBarHeightPX ? this.minBarHeightPX : (range.prices.length / maxCount * this.percentage)}%`);

        li.addEventListener('click', () => {
          const prices = [Math.round(range.min), Math.round(range.max)];
          this.onSelectedBar.emit(prices);
        });

        if (range.prices.length) {
          const label: HTMLElement = this.renderer.createElement('div');
          this.renderer.addClass(label, 'label-wp');
          this.renderer.addClass(label, 'price-only');
          label.innerText = `${range.prices.length} Offers`;

          this.renderer.appendChild(li, label);
        }

        this.renderer.appendChild(graph, li);
      });

      this.renderer.appendChild(this.elementRef.nativeElement, graph);

      this.oldChild = graph;
    }
  }

  private buildPaymentTimeLimitGraph() {
    if (this.modelData.min && this.modelData.max) {
      let unix = moment().unix();
      let day = 60 * 60 * 24;
      let today = unix - (unix % day);

      const diff = this.modelData.max - this.modelData.min;
      const precision = 60 * 60 * 24;
      let maxItems = Math.min(this.maxPaymentTimeLimitSteps, diff / precision);
      const step = diff / maxItems;
      const ranges = [];
      let lastValue = this.modelData.min;
      for (let i = 0; i < maxItems; i++) {
        const temp = {
          min: i === 0 ? lastValue - 1 : lastValue,
          max: lastValue + step,
          times: []
        };
        lastValue = lastValue + step;
        ranges[i] = temp;
      }
      if (!ranges.length) {
        return;
      }
      ranges[ranges.length - 1].max += 1;
      this.modelData.allTimes
        .map(item => item.time)
        .map(time => {
          ranges.map(range => {
            if (time !== today && time > range.min && time <= range.max) {
              range.times.push(time);
            }
          });
        });

      const maxCount = Math.max.apply(Math, ranges.map(function (o) {
        return o.times.length;
      }));

      if ((this.oldChild && this.elementRef.nativeElement) && this.elementRef.nativeElement.contains(this.oldChild)) {
        this.renderer.removeChild(this.elementRef.nativeElement, this.oldChild);
      }

      const graph: HTMLElement = this.renderer.createElement('ul');
      this.renderer.addClass(graph, 'graph-wp');
      this.renderer.addClass(graph, 'graph-wp-no-width');

      ranges.map(range => {

        const li: HTMLElement = this.renderer.createElement('li');
        this.renderer.setStyle(li, 'height', `${!range.times.length ?
          0 :
          (range.times.length / maxCount * this.percentage) < this.minBarHeightPX ?
            this.minBarHeightPX :
            (range.times.length / maxCount * this.percentage)}%`);
        this.renderer.setStyle(li, 'width', `100%`);


        li.addEventListener('click', () => {
          const val = range.max;
          this.onSelectedBar.emit(val);
        });

        if (range.times.length) {
          const label: HTMLElement = this.renderer.createElement('div');
          this.renderer.addClass(label, 'label-wp');
          this.renderer.addClass(label, 'price-only');
          label.innerText = `${range.times.length} Offers`;

          this.renderer.appendChild(li, label);
        }

        this.renderer.appendChild(graph, li);
      });

      this.renderer.appendChild(this.elementRef.nativeElement, graph);

      this.oldChild = graph;
    }
  }

  private buildTimeRangeGraph() {
    let tempMin = this.modelData.min;
    let tempMax = this.modelData.max;
    let items = this.modelData.allTimes;

    if (this.modelDestination === 'duration') {
      const times = this.modelData.map(item => item.time);
      tempMin = Math.min(...times);
      tempMax = Math.max(...times);
      items = this.modelData;
    }

    if (tempMin && tempMax) {
      const step = (tempMax - tempMin) / this.maxItems;
      const ranges = [];
      let lastValue = tempMin;
      for (let i = 0; i < this.maxItems; i++) {
        const temp = {
          min: i === 0 ? lastValue - 1 : lastValue,
          max: lastValue + step,
          prices: []
        };
        lastValue = lastValue + step;
        ranges[i] = temp;
      }
      ranges[ranges.length - 1].max += 1;

      items.map(item => {
        ranges.map(range => {
          if (item.time > range.min && item.time <= range.max) {
            range.prices.push(item);
          }
        });
      });

      const maxCount = Math.max.apply(Math, ranges.map(function (o) {
        return o.prices.length;
      }));

      if ((this.oldChild && this.elementRef.nativeElement) && this.elementRef.nativeElement.contains(this.oldChild)) {
        this.renderer.removeChild(this.elementRef.nativeElement, this.oldChild);
      }

      const graph: HTMLElement = this.renderer.createElement('ul');
      this.renderer.addClass(graph, 'graph-wp');

      ranges.map(range => {

        const li: HTMLElement = this.renderer.createElement('li');
        li.addEventListener('click', () => {
          const times = [Math.round(range.min), Math.round(range.max)];
          this.onSelectedBar.emit(times);
        });
        this.renderer.setStyle(li, 'height', `${!range.prices.length ? 0 : (range.prices.length / maxCount * this.percentage) < this.minBarHeightPX ? this.minBarHeightPX : (range.prices.length / maxCount * this.percentage)}%`);

        if (range.prices.length) {
          const labelWp: HTMLElement = this.renderer.createElement('div');
          this.renderer.addClass(labelWp, 'label-wp');

          const offerLabel: HTMLElement = this.renderer.createElement('div');
          this.renderer.addClass(offerLabel, 'count-item-label');
          offerLabel.innerText = `${range.prices.length} Offers`;
          this.renderer.appendChild(labelWp, offerLabel);

          const priceLabel: HTMLElement = this.renderer.createElement('div');
          this.renderer.addClass(priceLabel, 'price-item-label');
          const minPrice = Math.min(...range.prices.map(item => item.price));
          const maxPrice = Math.max(...range.prices.map(item => item.price));
          const currency = range.prices[0].currency;
          priceLabel.innerText = minPrice === maxPrice ? `${Math.floor(minPrice)}${currency}` : `${Math.floor(minPrice)}${currency}-${Math.ceil(maxPrice)}${currency}`;

          this.renderer.appendChild(labelWp, priceLabel);

          this.renderer.appendChild(li, labelWp);
        }

        this.renderer.appendChild(graph, li);

      });

      this.renderer.appendChild(this.elementRef.nativeElement, graph);

      this.oldChild = graph;
    }
  }
}
