import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

enum FARE_RULES_STATUS {
  FEE = 'w/ fee',
  ALLOWED = 'allowed',
  DISALLOWED = 'disallowed'
}

@Component({
  selector: 'app-fare-rules-capacity-info',
  templateUrl: './fare-rules-capacity-info.component.html',
  styleUrls: ['./fare-rules-capacity-info.component.scss']
})
export class FareRulesCapacityInfoComponent implements OnInit, OnChanges {

  @Input() fareRulesCapacity: any | undefined;
  @Input() mode: {default?: boolean, perLeg?: boolean, simple?: boolean, entireTrip?: boolean} = {};
  @Input() fontSize = 12;
  @Input() isCollapsed = false;
  @Input() showLines = true;

  FARE_RULES_STATUS = FARE_RULES_STATUS;

  constructor() { }

  statusColors = {
    ALLOWED: {
      color: {
        primaryColor: '#27B947',
        secondaryColor: '#32DD57',
      },
      status: 'allowed'
    },
    NOT_ALLOWED: {
      color: {
        primaryColor: '#CD0005',
        secondaryColor: '#F31B21',
      },
      status: 'disallowed'
    },
    FOR_FEE: {
      color: {
        primaryColor: '#FFC700',
        secondaryColor: '#FFE600',
      },
      status: 'w/ fee'
    },
    DEFAULT: {
      color: {
        primaryColor: '#D6D6D6',
        secondaryColor: '#D6D6D6',
      },
      status: ''
    },
  };

  fareRulesStatus = {
    changeColor: {
      primaryColor: '#CD0005',
      secondaryColor: '#F31B21',
    },
    changeStatus: '',
    cancelColor: {
      primaryColor: '#CD0005',
      secondaryColor: '#F31B21',
    },
    cancelStatus: '',
    cancelDescription: '',
    changeDescription: '',
  };

  ngOnInit(): void {
    this.prepareFareRulesData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fareRulesCapacity) {
      this.prepareFareRulesData();
    }
  }

  prepareFareRulesData() {
    const cancel = this.fareRulesCapacity ? this.fareRulesCapacity[0]?.cancel : '';
    const change = this.fareRulesCapacity ? this.fareRulesCapacity[0]?.change : '';

    const cancelRule = cancel?.toUpperCase().replace(' ', '_');
    this.fareRulesStatus.cancelColor = cancelRule ? this.statusColors[cancelRule].color : this.statusColors.DEFAULT.color;
    this.fareRulesStatus.cancelStatus = cancelRule ? this.statusColors[cancelRule].status : this.statusColors.DEFAULT.status;
    this.fareRulesStatus.cancelDescription = cancel ? cancel : '';

    const changeRule = change?.toUpperCase().replace(' ', '_');
    this.fareRulesStatus.changeColor = changeRule ? this.statusColors[changeRule].color : this.statusColors.DEFAULT.color;
    this.fareRulesStatus.changeStatus = changeRule ? this.statusColors[changeRule].status : this.statusColors.DEFAULT.status;
    this.fareRulesStatus.changeDescription = change ? change : '';
  }
}
