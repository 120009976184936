import { Injectable } from '@angular/core';
import { PrepareFareTypeInfo } from '../interfaces/fare-types';
import { Dictionary } from '../types/dictionary';
import {FlightInfo, Offer, SimpleOffer} from '../interfaces/offer';
import {BehaviorSubject, Subject} from 'rxjs';
import {Breakdown} from "../interfaces/price";
import {ShortPassenger} from "../interfaces/passenger";
import {Disclocures} from "../interfaces/disclosures";
import {Observable} from "rxjs/internal/Observable";
import {GetAirlinePipe} from "../pipes/get-airline.pipe";
import {ParseDurationPipe} from "../pipes/parse-flight-duration.pipe";


@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private offersSource$ = new BehaviorSubject<any[]>([]);
  private providersSource$ = new BehaviorSubject<any>({});
  offerGridSubject = new Subject<boolean>();
  cabins = ["ECONOMY", "PREMIUM ECONOMY", "BUSINESS", "FIRST"];
  upsellListOwners = ['AER', '1G'];

  constructor(private parseDuration: ParseDurationPipe,
              private getAirlinePipe: GetAirlinePipe) {}

  addOffers(data) {
    this.offersSource$.next(data);
  }

  getOffers(): Observable<any> {
    return this.offersSource$.asObservable();
  }

  getProviders(): Observable<string[]> {
    return this.providersSource$.asObservable();
  }

  addProviderErrors(providers: any) {
    this.providersSource$.next(providers);
  }

  getProviderErrors(): Observable<any> {
    return this.providersSource$.asObservable();
  }

  changeOfferGrid() {
    this.offerGridSubject.next(true);
  }

  getOfferGridChanges(): Observable<boolean> {
    return this.offerGridSubject.asObservable();
  }

  setFareTypesInfo(offer) {
    const prepareFareTypeInfo: PrepareFareTypeInfo = {
      commonFareType: {
        fareType: offer.flights[0].segments[0].detail.classOfService.fare.type,
        fareCode: offer.flights[0].segments[0].detail.classOfService.fare.code,
      },
      fareTooltipText: null,
      fareTypesCategory: null,
    };

    prepareFareTypeInfo.fareTooltipText = `${prepareFareTypeInfo.commonFareType.fareCode} - ${prepareFareTypeInfo.commonFareType.fareType}`;
    prepareFareTypeInfo.fareTypesCategory =
      Dictionary.FareTypeCategories.find((category) =>
        category.fareCodes.includes(prepareFareTypeInfo.commonFareType.fareCode)
      ) ??
      Dictionary.FareTypeCategories.find(
        (item) => item.groupName === Dictionary.fareTypeGroup.misc
      );

    return prepareFareTypeInfo;
  }

  getSimpleModelByOffers(offerCandidate: Offer[]): SimpleOffer[] {
    const simpleOffers = [];
    offerCandidate.map((offer: Offer) => {
      const simpleOffer = this.getSimpleOffer(offer);
      simpleOffers.push(simpleOffer);
    });
    return simpleOffers;
  }

  getSimpleOffer(offer) {
    const simpleOffer: SimpleOffer = {
      price: offer.price.consumer.total,
      currency: offer.price.consumer.currency,
      owner: offer.owner,
      offerID: offer.offerID,
      fareType: offer.flights[0].segments[0].detail.classOfService.fare.type,
      fareCode: offer.flights[0].segments[0].detail.classOfService.fare.code,
      cabins: [],
      fares: [],
      fareRulesCapacity: this.getFareRules(offer),
      fareBasisCode: offer.flights[0].segments[0].detail.classOfService.fare.basisCode,
      baggageAllowance: offer.flights.map(flight => flight.baggageAllowance),
      passengersData: this.getPassengersData(offer?.price?.provider?.breakdown),
      flightInfo: this.getFlightInfo(offer),
      paymentTimeLimit: offer.paymentTimeLimit,
      disclosures: [],
      preparedFareTypeInfo: this.setFareTypesInfo(offer),
    };
    offer.flights.map((flight, idx) => {
      const tempFare = {
        route: `${flight.departure.airportCode} → ${flight.arrival.airportCode}`,
        airlineNamesPerLeg: [],
      };
      let cabinsPerLeg = [];
      let uniqueDisclosures = [];
      flight.segments.map(seg => {
        if (seg.detail.classOfService.cabinDesignator) {
          if (seg.detail.classOfService) {
            cabinsPerLeg.push(seg.detail.classOfService.cabinDesignator);
          }
        }
        if (seg.marketingCarrier.name) {
          tempFare.airlineNamesPerLeg.push(seg.detail.classOfService.fare.marketingName);
        }

        let disclosuresByListKey = this.getDisclosuresByListKey(offer.disclosures, seg.detail.classOfService.disclosureRefs);
        if (disclosuresByListKey?.length) {
          let notIncludedDisclosures = this.getNotIncludedDisclosures(uniqueDisclosures, disclosuresByListKey);
          if (notIncludedDisclosures?.length) {
            uniqueDisclosures.push(notIncludedDisclosures[0]);
          }
        }
      });
      tempFare.airlineNamesPerLeg = Array.from(
        new Set(tempFare.airlineNamesPerLeg)
      );
      cabinsPerLeg = Array.from(new Set(cabinsPerLeg));
      if (cabinsPerLeg.length > 1) {
        cabinsPerLeg = cabinsPerLeg.reduce((acc, curr, index) => {
          return index === 0 ? acc + curr : acc + ', ' + curr;
        }, '');
      }
      simpleOffer.fares.push(tempFare);
      simpleOffer.cabins.push(cabinsPerLeg);
      simpleOffer.disclosures[idx] = uniqueDisclosures;
    });

    return simpleOffer;
  }

  getOffersWithSameOD(desireOffer: any, allOffers: any[], currentOD: number): any[] {
    if (!allOffers.length) {
      return [];
    }
    return allOffers.filter((offer) => {
      let flag = true;
      for (let i = 0; i <= currentOD; i++) {
        if (
          this.getODInfo(offer, i) !== this.getODInfo(desireOffer, i)
        ) {
          flag = false;
          return;
        }
      }
      return flag;
    });
  }

  extractMarketingNames(offer: any, flightIdx: number): string {
    return offer.flights && !!offer.flights[+flightIdx]
      ? offer.flights[flightIdx].segments.map(seg => `${seg.detail.classOfService?.fare?.marketingName}`).join('-')
      : '';
  }

  getODInfo(offer, flightIdx): string {
    if (!offer.flights || !offer.flights[flightIdx]) {
      return '';
    }

    const segments = offer.flights[flightIdx].segments;

    return segments.map(segment => {
      const { marketingCarrier, originDestination } = segment;
      const { airlineID, flightNumber } = marketingCarrier;
      const { date, time } = originDestination.departure;

      return `${airlineID}${flightNumber}${date}${time}`;
    }).join('-');
  }

  extractFlightCabinDesignators(offer: any) {
    const cabins = offer.flights.map( flight => {
      return flight.segments.map( flightSegment => {
        return flightSegment.detail.classOfService.cabinDesignator;
      });
    });

    return cabins.join('-');
  }

  getPassengersData(breakdowns: Breakdown[]): ShortPassenger[] {
    let passengers: ShortPassenger[] = [];

    if (breakdowns?.length > 0) {
      breakdowns.forEach(breakdown => {
        const tempPassengerData = breakdown.passengerRefs?.split(' ')?.map(ref => {
          return {travelerReference: ref, passengerType: breakdown.passengerType};
        });
        if (tempPassengerData) {
          passengers = [...passengers, ...tempPassengerData];
        }
      });
    }
    return passengers;
  }

  checkFareRules(offer: any, fareRulesToNumberMap, fareRulesFilter) {
    if (!fareRulesFilter) {
      return true;
    }

    let sortPriority = ['Not Allowed, For Fee, Allowed'];
    let fareRules = [];
    offer.flights.map(flight => {
      flight.segments.map(segment => {
        if (segment?.detail?.classOfService?.fareRules) {
          fareRules.push(segment.detail.classOfService.fareRules[0]);
        }
      });
    });

    fareRules.sort((a, b) => (sortPriority.indexOf(a.cancel) + sortPriority.indexOf(a.change)) - (sortPriority.indexOf(b.cancel) + sortPriority.indexOf(b.change)));

    if (fareRules.length) {
      return (
        fareRulesToNumberMap.get(fareRulesFilter[0]) <=
        fareRulesToNumberMap.get(fareRules[0].cancel) &&
        fareRulesToNumberMap.get(fareRulesFilter[1]) <=
        fareRulesToNumberMap.get(fareRules[0].change)
      );
    } else {
      if (
        fareRulesToNumberMap.get(fareRulesFilter[0]) > 0 ||
        fareRulesToNumberMap.get(fareRulesFilter[1]) > 0
      ) {
        return false;
      }
    }

    return true;
  }

  checkDisclosures(offer: any, checkedBaggage: string, refundableFares: string) {
    if (!checkedBaggage && !refundableFares) {
      return true;
    }
    let ok = true;
    if (checkedBaggage) {
      let baggageStatuses = {
        Included: true,
        NotIncluded: false,
      };
      let baggageStatus = baggageStatuses[checkedBaggage];
      let checkedBaggageOK = true;

      offer.flights?.map(flight => {
        let isAvailable = true;
        flight?.baggageAllowance?.checked?.map(check => {
          const baggageIncluded = check?.options?.every(option => option.quantity !== 0);

          if (!(isAvailable && ((baggageIncluded && baggageStatus) || (!baggageIncluded && !baggageStatus)))) {
            checkedBaggageOK = isAvailable = false;
          }
        });

        ok = checkedBaggageOK;
      });
    }
    return ok;
  }

  sortOffersByPrice(offers) {
   return offers.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
  }

  filterOffersByProvider(currentOffer, allOffers) {
    return allOffers.filter(offer => offer.owner === currentOffer.owner);
  }

  getLowestPriceOffersByProvider(currentOffer, allOffers) {
    let lowestPriceOffersByProvider = [currentOffer];
    let owners = [];
    allOffers.map(offer => {
      if (offer.owner !== currentOffer.owner && !owners.includes(offer.owner)) {
        lowestPriceOffersByProvider.push(offer);
        owners.push(offer.owner);
      }
    });
    return lowestPriceOffersByProvider;
  }

  getFlightInfo(offer: any): FlightInfo[] {
    const flightsInfo: FlightInfo[] = offer.flights.map( flight => {
      return {
        flightRoute: {
          arrivalAirportCode: flight?.arrival?.airportCode,
          arrivalAirportName: flight?.arrival?.airportName,
          arrivalAirportTerminal: flight?.arrival?.terminalName,
          departureAirportCode: flight?.departure?.airportCode,
          departureAirportName: flight?.departure?.airportName,
          departureAirportTerminal: flight?.departure?.terminalName,
        },
        baggage: flight.baggageAllowance,
        passengerData: this.getPassengersData(offer?.price?.provider?.breakdown),
        duration: this.parseDuration.transform(flight.duration),
        segmentsInfo: flight.segments.map(segment => {
          return {
            class: {
              code: segment?.detail?.classOfService?.code,
            },
            equipment: segment.equipment,
            seatsLeft: segment?.detail?.seatsLeft || "",
            flightTime: segment?.originDestination?.departure?.time + ' - ' + segment?.originDestination?.arrival?.time,
            arrivalDate: segment?.originDestination?.arrival?.date !== segment?.originDestination?.departure?.date ?
              segment?.originDestination?.arrival?.date : '',
            marketingCarrierFlightNumber: segment.marketingCarrier ? (segment.marketingCarrier.airlineID + ' ' + segment.marketingCarrier.flightNumber) : '',
            operatingCarrierFlightNumber: segment.operatingCarrier ? (segment.operatingCarrier.airlineID + ' ' + segment.operatingCarrier.flightNumber) : '',
            departureDate: segment?.originDestination?.departure?.date,
            operatingCarrierAirlineID: segment.operatingCarrier.airlineID,
            aircraftCode: segment.equipment.aircraftCode,
            airlineImageDescription: this.getAirlinePipe.transform(segment.operatingCarrier.airlineID)  + ' - ' + segment?.operatingCarrier?.name,
            airlineOperatedByDescription: this.getAirlinePipe.transform(segment.operatingCarrier.airlineID)  + ' - ' + segment?.operatingCarrier?.name,
            segmentDuration: this.parseDuration.transform(segment.detail.duration),
            fareBasisCode:  segment?.detail?.classOfService?.fare?.basisCode,
            segmentRoute: {
              arrivalAirportCode: segment?.originDestination?.arrival?.airportCode,
              arrivalAirportName: segment?.originDestination?.arrival?.airportName,
              departureAirportCode: segment?.originDestination?.departure?.airportCode,
              departureAirportName: segment?.originDestination?.departure?.airportName,
            },
            stopLocations: segment.detail?.stopLocations || ''
          };
        })
      };
    });

    return flightsInfo;
  }

  getDisclosuresByListKey(disclosures: Disclocures[], disclosureRefs: string[]): Disclocures[] {
    return disclosures?.filter(disclosure => {
      return disclosure.descriptions && disclosureRefs?.includes(disclosure.listKey);
    });
  }

  getFareRules(offer, idx = 0, leg = false) {
    let fareRules = [];
    let sortPriority = ['Not Allowed', 'For Fee', 'Allowed'];
    if (leg) {
      offer.flights[idx]?.segments.map(seg => {
        if (seg.detail.classOfService.fareRules) {
          fareRules.push(seg.detail.classOfService.fareRules[0]);
        }
      });
    } else {
      offer.flights?.map((flight) => {
        flight.segments.map(seg => {
          if (seg.detail.classOfService.fareRules) {
            fareRules.push(seg.detail.classOfService.fareRules[0]);
          }
        });
      });
    }
    fareRules.sort((a, b) => (sortPriority.indexOf(a.cancel) + sortPriority.indexOf(a.change)) - (sortPriority.indexOf(b.cancel) + sortPriority.indexOf(b.change)));
    return fareRules.length ? [fareRules[0]] : [];
  }

  getNotIncludedDisclosures(uniqueDisclosures, disclosuresByListKey) {
    if (uniqueDisclosures.length) {
      let newDisclosures = {
        descriptions: [],
        listKey: ''
      };
      newDisclosures.listKey = uniqueDisclosures[0].listKey;
      disclosuresByListKey[0].descriptions.map(discl => {
        if (!uniqueDisclosures[0].descriptions.includes(discl)) {
          newDisclosures.descriptions.push(discl);
        }
      });
      return [newDisclosures];
    } else {
      return disclosuresByListKey;
    }
  }

  isSelectedOfferFromUpsell(simpleOffer, desireOffer) {
    if (desireOffer?.owner === '1G') {
      const offer = this.getSimpleOffer(desireOffer);

      return offer.price === simpleOffer.price
        && JSON.stringify(offer.fares) === JSON.stringify(simpleOffer.fares)
        && JSON.stringify(offer.cabins) === JSON.stringify(simpleOffer.cabins);
    }
  }

  belongsToUpsellList(owner) {
    return this.upsellListOwners.includes(owner);
  }

  getOffersAndFlightIndexes(offers: any[], currentOffer: any, options: string[]) {
    const result = [];
    const uniqValue = new Set<number>();

    offers.map((offer) => {
      let isAvailable = !!offer.flights;
      offer.flights?.map((flight, index) => {
        let iterationOfferData = '1';
        let existingOfferData = '2';

        const flightNumberIO = currentOffer.flights[index].segments.map(segment => segment.flightNumber).join('').toLowerCase();
        const flightNumberEO = offer.flights[index].segments.map(segment => segment.flightNumber).join('').toLowerCase();

        switch (options[index]) {
          case 'upgrade':
          case 'keep':
            if (flightNumberIO === flightNumberEO) {
              iterationOfferData = '1';
              existingOfferData = '1';
            }
            uniqValue.add(index);
            break;
          case 'change':
            iterationOfferData = '1';
            existingOfferData = '1';
            break;
        }
        if (iterationOfferData === existingOfferData && isAvailable) {
        } else {
          isAvailable = false;
        }
      });
      if (isAvailable) {
        result.push(offer);
      }
    });

    return {offers: result, indexes: Array.from(uniqValue)};
  }

}
