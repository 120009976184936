import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NDCApiService} from '../shared/services/ndc-api.service';
import {HelpersService} from '../shared/services/helpers.service';
import {SentryService} from '../shared/services/sentry.service';
import {LocalStorageService} from '../shared/services/local-storage.service';
import {convertPhoneIntoString} from "../shared/adapters/v1_2/adapters";

@Component({
  selector: 'app-service-list-pre-modal',
  templateUrl: './service-list-pre-modal.component.html',
  styleUrls: ['./service-list-pre-modal.component.scss']
})
export class ServiceListPreModalComponent implements OnInit {
  @Input() owner;
  @Input() shoppingResponseID;
  @Input() passengersData = [];
  @Input() passengersList = [];
  @Input() flights = [];
  @Input() reshopOfferID;
  @Input() rebook = false;
  @Input() discounts: {[key: string]: string} = {};

  @Output() emitDismiss = new EventEmitter();
  @Output() emitClose = new EventEmitter();
  @Output() emitServicesSelected = new EventEmitter();
  @ViewChild('cardBodyContainer', {static: true}) cardBodyContainer: ElementRef;

  selectedServicesMapCopy = {};
  selectedServicesCopy = [];
  step = 1;
  showServiceListLoader = false;
  serviceListError: any = {};
  responseWarnings = [];
  servicesPerTraveler = {};
  selectedServices = [];
  selectedServicesMap = {};
  passengers = [];
  validationErrors: any = {};
  segments = [];

  constructor(
    public service: NDCApiService,
    public ls: LocalStorageService,
    private helpers: HelpersService,
    private sentryService: SentryService
  ) {
  }

  ngOnInit() {
    this.passengers = this.preparePassengers();
    this.service.owner = this.sentryService.owner = this.owner;
    setTimeout(() => this.actionDisplayServiceList(), 0);
  }

  setStep(step: number) {
    this.step = step;
  }

  actionDisplayServiceList() {
    let body: any = {};

    if (this.reshopOfferID) {
      body.reshopOfferID = this.reshopOfferID;
    } else {
      body.shoppingResponseID = this.shoppingResponseID;

      if (this.owner === 'VY' && this.discounts && Object.values(this.discounts).length) {
        body.discounts = this.discounts;
      }
    }

    if (this.owner === 'AER') {
      body.passengers = this.passengers.map(psg => {
        let newPsg = {...psg};
        newPsg.data.phone = convertPhoneIntoString(psg.data.phone);
        return newPsg;
      });
    }

    this.serviceListError = '';
    this.responseWarnings = [];

    if (Object.keys(this.servicesPerTraveler).length === 0) {
      this.servicesPerTraveler = {};
      this.showServiceListLoader = true;

      return this.service.sendServiceList(body)
        .then((res: any) => {
          this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, 200, res);
          this.responseWarnings = this.helpers.getWarningsFromResponse(res);
          if (res?.services && res?.segments) {
            ({
              servicesPerTraveler: this.servicesPerTraveler,
            } = this.helpers.groupServicesBySegment(res.services, res.segments));
            this.segments = res.segments;
          }

          if (this.rebook) {
            this.servicesForRebook();
          }

          this.showServiceListLoader = false;
        })
        .catch((res) => {
          this.showServiceListLoader = false;
          this.serviceListError = this.helpers.getError(res);
          this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, res.status, res);
          if (this.helpers.isCriticalError(res)) {
            throw res;
          }
        });
    }
    return Promise.resolve();
  }

  servicesForRebook () {
    Object.keys(this.servicesPerTraveler).map(traveler => {
      if (Object.keys(this.servicesPerTraveler[traveler]?.perLeg)) {
        Object.keys(this.servicesPerTraveler[traveler].perLeg).map(route => {
          this.flights.map(flight => {
            let key = flight.departure.airportCode + '-' + flight.arrival.airportCode;
            if (route !== key) {
              delete this.servicesPerTraveler[traveler]?.perLeg[route];
            }
          });
        });
      }
      if (Object.keys(this.servicesPerTraveler[traveler].perSegment)) {
        Object.keys(this.servicesPerTraveler[traveler].perSegment).map(route => {
          let routes = [];
          this.flights.map(flight => {
            let key = '';
            flight.segments.map(segment => {
              key = segment.originDestination.departure.airportCode + '-' + segment.originDestination.arrival.airportCode;
              routes.push(key);
            });
          });
          if (!routes.includes(route)) {
            delete this.servicesPerTraveler[traveler]?.perSegment[route];
          }
        });
      }
    });
  }

  removeService(srv, travelerReferences) {
    this.removeSelectedService(srv, travelerReferences);
    this.applyNewServiceSelection();
    if (!this.selectedServicesCopy.length) {
      this.setStep(1);
    }
  }

  removeSelectedService(service, passengerRef) {
    let code = service.serviceID + passengerRef + service.segmentReferences;
    const copyService = JSON.parse(JSON.stringify(service));
    if (this.selectedServicesMapCopy[code]) {
      service.bookingInstructions?.instructions?.forEach((instruction, instructionIdx) => {
        delete this.validationErrors[code + instructionIdx];
      });
      delete this.selectedServicesMapCopy[code];
      this.selectedServicesCopy = this.selectedServicesCopy.filter((srv) => {
        return srv.serviceID + srv.segmentReferences + srv.travelerReferences !== copyService.serviceID + copyService.segmentReferences + passengerRef;
      });
    }
  }

  applyNewServiceSelection() {
    this.selectedServices = JSON.parse(JSON.stringify(this.selectedServicesCopy));
    this.selectedServicesMap = JSON.parse(JSON.stringify(this.selectedServicesMapCopy));
  }

  showSelectedServices() {
    if (Object.keys(this.validationErrors).length === 0) {
      this.applyNewServiceSelection();
      this.setStep(2);
    }
  }

  selectServices() {
    if (!this.selectedServices.length) {
      return;
    }
    this.selectedServices.map((srv, idx) => {
      if (this.selectedServices[idx]?.quantity > 1) {
        this.selectedServices[idx].price.consumer.total = this.selectedServices[idx].quantity * this.selectedServices[idx].price.consumer.total;
      }
    });
    this.emitServicesSelected.emit(this.selectedServices);
  }

  /*orderChange() {
    if (!this.selectedServices.length) {
      return;
    }
    if (!this.paymentObject) {
      return;
    }

    let services = [];
    this.selectedServices.map((service) => {
      let type = 'service';

      switch (service.ref) {
        case 'ExcessBaggage':
          type = 'baggage';
          break;
        case 'Seat':
          type = 'seat';
          break;
      }

      let serviceItem = {
        type: type,
        owner: service.owner,
        serviceID: service.serviceID,
        segmentReference: service.segmentReferences,
        travelerReference: service.travelerReferences,
        quantity: service.quantity,
        action: 'Create',
      };
      services.push(serviceItem);
    });

    let body = {
      id: this.id,
      payment: this.paymentObject,
      services: services,
      passengers: this.order.passengers,
    };
    body.passengers = body.passengers.map((item) => {
      delete item.document;
      return item;
    });

    this.paymentLoader = true;
    this.orderChangeError = '';
    this.orderChangeWarningMessage = [];

    this.service.sendOrderChange(body, this.owner)
      .then((res: any) => {
        this.showServiceListLoader = false;
        this.paymentLoader = false;

        this.orderChangeWarningMessage = this.helpers.getWarningsFromResponse(res);

        this.setStep(4);
        this.emitSendOrderChangeSuccess.emit(true);
      })
      .catch((res) => {
        this.paymentLoader = false;
        this.cardBodyContainer.nativeElement.scrollTop = 0;
        this.orderChangeError = this.helpers.getError(res.status, res);
        if (this.helpers.isHttpError(res)) {
          throw res;
        }

      });
  }*/

  preparePassengers() {
    return this.passengersData
      .map((p, idx) => {
        return {
          travelerReference: this.passengersList[idx].travelerReference,
          data: p.data,
          passengerType: this.passengersList[idx].passengerType,
        };
      });
  }

  onEmitUpdatedValues({selectedServicesCopy, selectedServicesMapCopy, validationErrors}) {
    this.selectedServicesCopy = selectedServicesCopy;
    this.selectedServicesMapCopy = selectedServicesMapCopy;
    this.validationErrors = validationErrors;
  }
}
