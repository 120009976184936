import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {
  constructor() {

  }

  removeItem(item: string) {
    localStorage.removeItem(item);
  }

  setSkipUsingChromeBrowser() {
    localStorage.requireChromeBrowser = "false";
  }

  get skipUsingChromeBrowser() {
    return !!localStorage.requireChromeBrowser;
  }

  removeRequireChromeBrowser () {
    localStorage.removeItem('requireChromeBrowser');
  }

  get settings(): any {
    return localStorage.settings ? JSON.parse(localStorage.settings) : {};
  }

  set settings(value) {
    localStorage.settings = JSON.stringify(value);
  }

  setHrefLink(value) {
    localStorage.hrefLink = value;
  }

  get hrefLink() {
    return localStorage.hrefLink;
  }

  get sessionID() {
    return localStorage.sessionID ? localStorage.sessionID : '';
  }

  set sessionID(value) {
    localStorage.sessionID = value;
  }

  set versionNumber(value) {
    localStorage.versionNumber = value;
  }

  get appVersion() {
    return this.settings.version ? this.settings.version : 'v1.2';
  }

  get token() {
    return localStorage.token;
  }

  get refreshToken() {
    return localStorage.refresh_token;
  }

  set token(value) {
    localStorage.token = value;
  }

  get umbrellaToken() {
    return localStorage.umbrellaToken;
  }

  setUmbrellaToken(value) {
    localStorage.umbrellaToken = value;
  }

  get clientId() {
    return localStorage.client_id;
  }

  setClientId(value) {
    localStorage.client_id = value;
  }

  set isSubagenciesAvailable(value) {
    localStorage.is_subagencies_available = value;
  }

  get isSubagenciesAvailable() {
    return localStorage.is_subagencies_available === 'true';
  }

  set profilesType(value) {
    localStorage.profiles_type = value;
  }

  get profilesType() {
    return localStorage.profiles_type;
  }

  set collapseOptions(value: any) {
    localStorage.collapse_options = value;
  }

  get collapseOptions() {
    return localStorage.collapse_options;
  }

  set flightType(value) {
    localStorage.flightType = value;
  }

  get flightType() {
    return localStorage.flightType;
  }

  setUmbrellaRefreshToken(value) {
    localStorage.refresh_token = value;
  }

  setUmbrellaCompany(value) {
    localStorage.umbrellaCompany = JSON.stringify(value);
  }

  get UmbrellaCompany() {
    return localStorage.umbrellaCompany;
  }

  setProfileCorporate(value) {
    localStorage.profileCorporate = JSON.stringify(value);
  }

  removeProfileCorporate() {
    delete localStorage.profileCorporate;
  }

  get profileCorporate() {
    return localStorage.profileCorporate;
  }

  get consumer() {
    return localStorage.consumer;
  }

  set consumer(value) {
    localStorage.consumer = value;
  }

  get card() {
    let v;
    try {
      v = JSON.parse(localStorage.card);
    } catch (err) {

    }
    return v;
  }

  set card(value) {
    localStorage.card = JSON.stringify(value);
  }

  get userType() {
    return localStorage.userType;
  }

  set userType(value) {
    localStorage.userType = value;
  }

  get email() {
    return localStorage.email;
  }

  set email(value) {
    localStorage.email = value;
  }

  get username() {
    return localStorage.username;
  }

  set username(value) {
    localStorage.username = value;
  }

  get agentID() {
    return localStorage.agentID;
  }

  set agentID (value) {
    localStorage.agentID = value;
  }

  get consumerCode() {
    return localStorage.consumer_code;
  }

  set consumerCode(value) {
    localStorage.consumer_code = value;
  }

  get role() {
    return localStorage.role;
  }

  set role(value) {
    localStorage.role = value;
  }

  get agency() {
    return localStorage.agency;
  }

  set agency(value) {
    localStorage.agency = value;
  }

  removeAgency() {
    delete localStorage.agency;
  }

  get agencyID() {
    return localStorage.agency_id;
  }

  set agencyID(value) {
    localStorage.agency_id = value;
  }

  get notificationMessage() {
    return localStorage.notificationMessage;
  }

  set notificationMessage(value) {
    localStorage.notificationMessage = value;
  }

  removeNotificationMessage() {
    delete localStorage.notificationMessage;
  }

  get isSuperagency() {
    return localStorage.is_superagency;
  }

  set isSuperagency(value) {
    localStorage.is_superagency = value;
  }

  get orderWarnTabsInfo() {
    return localStorage.order_warn_tabs_info ? JSON.parse(localStorage.order_warn_tabs_info) : [];
  }

  set orderWarnings(value) {
    localStorage.order_warnings = value;
  }

  get orderWarnings() {
    return localStorage.order_warnings;
  }

  set timezone(value) {
    localStorage.timezone = value;
  }

  get timezone() {
    return localStorage.timezone;
  }

  set currentDate(value) {
    localStorage.currentDate = value;
  }

  get currentDate() {
    return localStorage.currentDate;
  }

  set warningsDisabled(value) {
    localStorage.warningsDisabled = value;
  }

  get warningsDisabled() {
    return localStorage.warningsDisabled === 'true';
  }

  removeUmbrellaCompany() {
    delete localStorage.umbrellaCompany;
  }

  set providers(value) {
    localStorage.providers = JSON.stringify(value);
  }

  get providers() {
    return localStorage.providers ? JSON.parse(localStorage.providers) : [];
  }

  set isRemoveAgentInfoModalSkipped(value) {
    localStorage.isRemoveAgentInfoModalSkipped = value;
  }

  get isRemoveAgentInfoModalSkipped() {
    return localStorage.isRemoveAgentInfoModalSkipped === 'true';
  }

  set isVoidingInfoModalSkipped(value) {
    localStorage.isVoidingInfoModalSkipped = value;
  }

  get isVoidingInfoModalSkipped() {
    return localStorage.isVoidingInfoModalSkipped === 'true';
  }

  set isInfoModalSkipped(value) {
    localStorage.isInfoModalSkipped = value;
  }

  get isInfoModalSkipped() {
    return localStorage.isInfoModalSkipped === 'true';
  }

  resetStorage() {
    delete localStorage.token;
    delete localStorage.agency;
    delete localStorage.userType;
    delete localStorage.lastTokenUsageTime;
    this.resetUmbrellaStorage();
  }

  resetUmbrellaStorage() {
    delete localStorage.umbrellaCompany;
    delete localStorage.umbrellaToken;
    delete localStorage.refresh_token;
  }

  resetHrefLink() {
    delete localStorage.hrefLink;
  }
}
