import {Component, Input, OnInit} from '@angular/core';
import {DisclosureDescription} from "../../interfaces/disclosures";

@Component({
  selector: 'app-disclosure-icon',
  templateUrl: './disclosure-icon.component.html',
  styleUrls: ['./disclosure-icon.component.scss']
})
export class DisclosureIconComponent implements OnInit {

  @Input() disclosureCategory: string;
  @Input() disclosures: DisclosureDescription[];
  @Input() isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

}
