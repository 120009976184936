import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { HelpersService } from '../../shared/services/helpers.service';
import { AuthService } from '../../shared/services/auth.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CorporateDiscountCodesService } from "../../shared/services/corporate-discount-codes.service";
import { CommonFareType, FareTypesCategory, PrepareFareTypeInfo } from "../../shared/interfaces/fare-types";
import {FlightInfo, SimpleOffer} from "../../shared/interfaces/offer";
import {OfferService} from "../../shared/services/offer.service";
import {NDCApiService} from "../../shared/services/ndc-api.service";
import {SentryService} from "../../shared/services/sentry.service";
import {Dictionary} from "../../shared/types/dictionary";


@Component({
  selector: 'app-offer-info',
  templateUrl: './offer-info.component.html',
  styleUrls: ['./offer-info.component.scss']
})
export class OfferInfoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() offerCandidate: any;
  @Input() checkObject: any;
  @Input() searchTypeOption: string;
  @Input() urlData;
  @Input() isReshop = false;
  @Output() emitSelectedLeg = new EventEmitter();
  @Output() emitOpenGroupOptions = new EventEmitter();
  @Output() emitOfferForReshop = new EventEmitter();

  fareNames = [];
  isShowGroupBar = false;
  fareTypeInfo: PrepareFareTypeInfo | null = null;
  fareTypesCategories: FareTypesCategory[] = Dictionary.FareTypeCategories;
  simpleOffers = [];
  simpleOffersByProvider = [];
  totalSimpleOffers = [];
  offer;
  groupNames = [];
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  selectedCorporate;

  offerURL = '';

  flightsInfo: FlightInfo[] = [];
  offerExpired = false;
  showLoader = false;
  otherOfferCandidate = [];
  offerPriceResponseDataByProvider: any = {};


  constructor(public helpers: HelpersService,
              private authService: AuthService,
              public ls: LocalStorageService,
              private corporateDiscountCodesService: CorporateDiscountCodesService,
              private offerService: OfferService,
              public service: NDCApiService,
              public sentryService: SentryService
  ) {
  }

  ngOnInit() {
    this.prepareFareInfo();
    this.flightsInfo = this.offerService.getFlightInfo(this.offer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offerCandidate) {
      const isArr = Array.isArray(this.offerCandidate);

      if (isArr) {
        this.offerCandidate.sort((a, b) => a.price.consumer.total - b.price.consumer.total);
        this.offer = this.offerCandidate[0];
        this.totalSimpleOffers = this.offerService.getSimpleModelByOffers(this.offerCandidate);
        this.simpleOffersByProvider = this.offerService.getLowestPriceOffersByProvider(this.offer, this.totalSimpleOffers);
        this.setSimpleOffers();
        this.prepareFareTypeCategories();
        this.offerPriceResponseDataByProvider = {};
      } else {
        this.offer = this.offerCandidate;
      }

      let corporate = null;
      this.corporateDiscountCodesService.selectedCorporate$
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((currentCorporate) => corporate = currentCorporate);

      this.selectedCorporate = corporate;

      if (this.urlData) {
        this.offerURL = this.helpers.setOfferUrl(this.offer, this.urlData, this.selectedCorporate);
      }

      this.prepareFareTypesInfo();
    }
  }

  private prepareFareInfo() {
    this.fareNames = this.helpers.prepareFareInfo(this.offer, true, true);
  }

  selectLeg(index) {
    const filterData = {
      offer: this.offer,
      fixedOriginDestination: `originalDestination-${index}`
    };
    this.emitSelectedLeg.emit(filterData);
  }

  toggleGroupBar(show?: boolean) {
    this.isShowGroupBar = show || !this.isShowGroupBar;
    if (this.isShowGroupBar) {
      this.emitOpenGroupOptions.emit(true);
      if (this.offerService.belongsToUpsellList(this.offer.owner)) {
        if (this.offerPriceResponseDataByProvider[this.offer.owner]?.groupedOffers?.length) {
          this.simpleOffers = this.offerPriceResponseDataByProvider[this.offer.owner].groupedOffers;
        } else {
          this.sendOfferPrice();
        }
      }
    }
  }

  sendOfferPrice() {
    this.showLoader = true;
    let body = {
      offerID: this.offer.offerID,
      type: 'UpsellOptions'
    };
    this.service.sendOfferPrice(body)
      .then((response: any) => {
        this.offerPriceResponseDataByProvider[this.offer.owner] = {};
        this.showLoader = false;
        if (response && response.otherOffers) {
          let otherOffers = response.otherOffers;
          if (otherOffers?.length) {
            if (this.offer.owner !== '1G') {
              otherOffers?.unshift(this.offer);
            }
            this.offerCandidate = this.offerCandidate.concat(otherOffers);
            let totalOtherSimpleOffers = this.offerService.getSimpleModelByOffers(otherOffers);
            this.simpleOffers = this.offerService.sortOffersByPrice(totalOtherSimpleOffers);
            this.offerPriceResponseDataByProvider[this.offer.owner].groupedOffers = this.simpleOffers;
          }
        }
      }).catch((err) => {
      this.showLoader = false;
      this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, err.status, err);
      if (this.helpers.isCriticalError(err)) {
        throw err;
      }
    });
  }

  setSimpleOffers() {
    let filteredTotalSimpleOffers = this.offerService.filterOffersByProvider(this.offer, this.totalSimpleOffers);
    this.simpleOffers = this.offerService.sortOffersByPrice(filteredTotalSimpleOffers);
  }

  setSelectedOffer({ offerID, isOpenNewWindow }, updateOffers = false) {
    let value = this.offerCandidate.find(item => item.offerID === offerID) || this.otherOfferCandidate.find(item => item.offerID === offerID);
    if (value) {
      this.offer = value;
    }
    this.flightsInfo = this.offerService.getFlightInfo(this.offer);
    this.prepareFareInfo();

    this.prepareFareTypesInfo(true);

    if (isOpenNewWindow && !this.isReshop) {
      this.offerURL = this.helpers.setOfferUrl(this.offer, this.urlData, this.selectedCorporate);
      this.openOffer();
    }
    if (!isOpenNewWindow && this.isReshop) {
      this.emitOfferForReshop.emit(value);
    }
    if (updateOffers) {
      this.setSimpleOffers();
      this.toggleGroupBar(true);
    }
  }

  openOffer() {
    if (this.isReshop) {
      this.emitOfferForReshop.emit(this.offer);
    } else {
      this.offerURL = this.helpers.setOfferUrl(this.offer, this.urlData, this.selectedCorporate);
      window.open(this.offerURL, "_blank");
    }
  }

  private prepareFareTypesInfo(swapTypeInfo = false) {
    const commonFareType: CommonFareType | null = this.helpers.extractFareType(this.offer);
    if (swapTypeInfo && commonFareType && this.fareTypeInfo) {
      this.groupNames.push({
        groupName: this.fareTypeInfo.fareTypesCategory.groupName,
        backgroundColor: this.fareTypeInfo.fareTypesCategory.backgroundColor
      });
    }
    this.fareTypeInfo = this.helpers.prepareFareTypesInfo(commonFareType, this.fareTypesCategories);
    if (this.fareTypeInfo) {
      this.groupNames = this.groupNames.filter(item => item.groupName !== this.fareTypeInfo?.fareTypesCategory.groupName);
    }
  }

  private prepareFareTypeCategories() {
    if (this.simpleOffers.length) {
      const groupNamesSet = [];
      this.groupNames = [];
      this.simpleOffers.forEach((simpleOffer: SimpleOffer) => {
        if (!groupNamesSet.includes(simpleOffer.preparedFareTypeInfo.fareTypesCategory.groupName)) {
          groupNamesSet.push(simpleOffer.preparedFareTypeInfo.fareTypesCategory.groupName);
          this.groupNames.push({
            groupName: simpleOffer.preparedFareTypeInfo.fareTypesCategory.groupName,
            backgroundColor: simpleOffer.preparedFareTypeInfo.fareTypesCategory.backgroundColor
          });
        }
      });
    }
  }

  onOfferExpired(expired) {
    this.offerExpired = expired;
  }

  get consumerPrice() {
    return this.offer.price.consumer;
  }

  get oldTotalPrice() {
    return this.consumerPrice.total + this.consumerPrice.discount.value;
  }

  get isDiscountExists() {
    return !!this.offer.price.consumer.discount?.value;
  }

  get discountPercentage() {
    const discount = ((this.oldTotalPrice - this.consumerPrice.total) / this.oldTotalPrice) * 100;
    return Math.round(discount);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
