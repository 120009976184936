import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EditFrequentFlyerNumber, FrequentFlyerNumber} from "../../types/profile";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {rotateAnimation} from "angular-animations";


@Component({
  selector: 'app-frequent-flyer-number',
  templateUrl: './frequent-flyer-number.component.html',
  styleUrls: ['./frequent-flyer-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    rotateAnimation({ degrees: -135, duration: 500 }),
  ]
})
export class FrequentFlyerNumberComponent implements OnInit, OnDestroy {
  @Input() flyerNumbers: FrequentFlyerNumber[];
  @Input() airlines: string[];
  @Input() showAddedEditedLoader = false;
  @Input() showRemovedLoader = false;
  @Input() isCardView = true;
  @Input() validateTriggered = false;
  @Output() emitAddFrequentNumber: EventEmitter<FrequentFlyerNumber> = new EventEmitter<FrequentFlyerNumber>();
  @Output() emitRemoveFrequentNumber: EventEmitter<FrequentFlyerNumber> = new EventEmitter<FrequentFlyerNumber>();
  @Output() emitEditFrequentNumber: EventEmitter<EditFrequentFlyerNumber> = new EventEmitter<EditFrequentFlyerNumber>();

  frequentFlyerNumberForm: FormGroup;
  formControlNames: FrequentFlyerNumber = {
    alliance: 'alliance',
    member_number: 'member_number'
  };
  isShowForm = false;
  editIdx = -1;
  removeFrequentNumberInfo: FrequentFlyerNumber;

  animationState = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.frequentFlyerNumberForm = this.fb.group({
      [this.formControlNames.alliance]: ['', Validators.required],
      [this.formControlNames.member_number]: ['', [Validators.required]]
    });
  }

  validate() {
    this.validateTriggered = true;
    return this.frequentFlyerNumberForm.valid;
  }

  showForm() {
    this.isShowForm = true;
    this.validateTriggered = false;
    this.resetForm();
  }

  startEditFrequentNumber(idx: number, fn: any) {
    this.resetForm();
    this.frequentFlyerNumberForm.setValue(fn);
    this.isShowForm = true;
    this.editIdx = idx;
  }

  finishEditFrequentNumber() {
    if (!this.validate()) {
      return false;
    }
    this.emitEditFrequentNumber.emit({
      idx: this.editIdx,
      frequentFlyerNumber: this.frequentFlyerNumberForm.value
    });
    this.resetForm();
    this.isShowForm = false;
    this.editIdx = -1;
  }

  addFrequentNumber(isShowForm = false) {
    if (!this.validate()) {
      return false;
    }
    this.emitAddFrequentNumber.emit(this.frequentFlyerNumberForm.value);
    this.resetForm();
    this.isShowForm = isShowForm;
  }

  cancelFrequentNumber() {
    this.isShowForm = false;
    this.editIdx = -1;
  }

  removeFrequentNumber(c?) {
    this.emitRemoveFrequentNumber.emit(this.removeFrequentNumberInfo);
    c('ok');
  }

  resetForm() {
    this.frequentFlyerNumberForm.reset();
    this.validateTriggered = false;
  }

  openRemoveModal(content, frequentFlyerNumber: FrequentFlyerNumber, idx, size: any = 'sm') {
    this.removeFrequentNumberInfo = frequentFlyerNumber;
    this.open(content, size);
  }

  open(content, size: any = 'sm') {
    this.modalService.open(content, {
      size,
      keyboard: false,
      backdrop: 'static'
    }).result.then((result) => {}, (reason) => {});
  }

  toggleFFN() {
    this.animationState = !this.animationState;
    if (this.animationState) {
      this.showForm();
    } else {
      this.isShowForm = false;
    }
  }

  ngOnDestroy() {
  }
}
