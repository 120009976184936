import {Pipe, PipeTransform} from "@angular/core";


@Pipe({ name: 'getMaterialIconsPipe' })
export class GetMaterialIconsPipe implements PipeTransform {
  transform(key: string) {

    if (!key) { return ''; }

    switch (key) {
        case 'MEALS':
        return 'fastfood';
        case 'BAGGAGE_CARRYON':
        return 'carry_on_bag';
        case 'BAGGAGE_CHECKED':
        return 'luggage';
        case 'SEATRES':
        return 'airline_seat_legroom_normal';
        case 'CHANGE':
        return 'sync';
        case 'REFUND':
        return 'money_off';
        case 'LEATHER_SEAT':
        return 'airline_seat_recline_normal';
        case 'CHECKIN':
        return 'how_to_reg';
        case 'BAR_SERVICE':
        return 'local_bar';
        case 'WINDOW_AISLE':
        return 'event_seat';
        case 'LOW_PRIORITY':
        case 'PRIORITY':
        case 'STANDBY':
        return 'contact_support';
        case 'MILES':
        return 'flightsmode';
        case 'CANCEL':
        return 'highlight_off';
        case 'SEAT':
        return 'flight_class';
        case 'LOUNGE':
        return 'weekend';
        case 'SERVICE':
        return 'local_offer';
        case 'ENTERTAINMENT':
        return 'smart_display';
      default:
        return 'contact_support';
    }


  }
}
