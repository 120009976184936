import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {OrderComponent} from "./order.component";
import {OrderDetailComponent} from "./order-detail/order-detail.component";
import {OrderListComponent} from "./order-list/order-list.component";
import {AuthGuard} from "../shared/guards/auth.guard";
import {ShowFrontGuard} from "../shared/guards/show-front.guard";
import {KeycloakGuard} from "../shared/guards/keycloak-guard";

const routes: Routes = [
  {
    path: 'orders',
    canActivate: [AuthGuard, ShowFrontGuard, KeycloakGuard],
    data: {route: 'orders'},
    component: OrderComponent,
    children: [
      {
        path: ':id',
        component: OrderDetailComponent
      },
      {
        path: ':owner/:id',
        component: OrderDetailComponent
      },
      {
        path: '',
        component: OrderListComponent
      }]
  },
  {
    path: 'subagencies',
    canActivate: [AuthGuard, ShowFrontGuard, KeycloakGuard],
    data: {route: 'subagencies'},
    component: OrderComponent,
    children: [
      {
        path: ':provider/:id',
        component: OrderDetailComponent
      },
      {
        path: ':provider/:id',
        component: OrderDetailComponent
      },
      {
        path: '',
        component: OrderListComponent
      }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRoutingModule {}
