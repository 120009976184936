import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {Profile} from '../types/profile';
import {ErrorResponse, MetaResponse} from '../types/system';

@Injectable({
  providedIn: 'root'
})

export class HubProfileService {
  private subUrl = `${environment.ndcApiEndpointV2}agent/pms/profile/`;
  private subRestUrl = `${environment.ndcApiEndpointV2}agent/pms/profiles/rest/`;
  role: string;

  constructor(
    public http: HttpClient) {
  }

  getAll(corporateID: string) {
    const params = new HttpParams().set('corporate_id', corporateID);
    const url = this.subRestUrl;
    return this.http.get<HttpResponse<Profile[]>>(url, this.options(params));
  }

  get(id: string) {
    const url = this.subRestUrl + id;

    return this.http.get<HttpResponse<Profile>>(url, this.options());
  }

  create(model) {
    const url = this.subRestUrl;
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, model, this.options());
  }

  update(model) {
    const url = this.subRestUrl + model.id;

    return this.http.put<HttpResponse<MetaResponse | ErrorResponse>>(url, model, this.options());
  }

  delete(id) {
    const url = this.subRestUrl + id;
    return this.http.delete<HttpResponse<MetaResponse>>(url, this.options());
  }

  private options(params?: HttpParams) {
    let options: any = {
      params,
      observe: 'response',
    };
    return options;
  }
}
