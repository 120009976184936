export const emptyFlightData = [
    {
        arrival: {
            airportCode: "-",
            airportName: "-",
            countryID: "",
            date: "-",
            parentLocation: "",
            terminalName: "-",
            time: "-"
        },
        departure: {
            airportCode: "-",
            airportName: "-",
            countryID: "",
            date: "-",
            parentLocation: "",
            terminalName: "-",
            time: "-"
        },
        flightDuration: "-",
        flightKey: "-",
        flightSegments: [
            {
                equipment: {
                    aircraftCode: "-",
                    name: "-"
                },
                flightDetail: {
                    classOfService: {
                        cabinDesignator: "-",
                        code: "-",
                        disclosureRefs: [
                            ""
                        ],
                        disclosures: {
                            ADT: {
                                fareRules: {
                                    penalties: [
                                        {
                                            amounts: [
                                                {
                                                    amount: 0,
                                                    currency: "-",
                                                    providerAmount: 0,
                                                    providerCurrency: "-",
                                                    remark: ""
                                                }
                                            ],
                                            description: "-",
                                            type: "-"
                                        },                                     
                                    ]
                                }
                            },
                            CHD: {
                                fareRules: {
                                    penalties: [
                                        {
                                            amounts: [
                                                {
                                                    amount: 0,
                                                    currency: "-",
                                                    providerAmount: 0,
                                                    providerCurrency: "-",
                                                    remark: ""
                                                }
                                            ],
                                            description: "-",
                                            type: "-"
                                        },                                     
                                    ]
                                }
                            }
                        },
                        fareCabin: "-",
                        fareStandardName: "-",
                        marketingName: "-",
                        refs: ""
                    },
                    fareCabin: "-",
                    fareCode: "-",
                    fareStandardName: "-",
                    fareType: "-",
                    flightDuration: "-",
                    flightSegmentType: "-",
                    stopQuantity: "-"
                },
                flightNumber: "-",
                marketingCarrier: {
                    airlineID: "-",
                    flightNumber: "-",
                    name: "-"
                },
                operatingCarrier: {
                    airlineID: "-",
                    disclosures: [
                        {
                            descriptions: [
                                {
                                    text: "-"
                                }
                            ],
                            listKey: ""
                        }
                    ],
                    flightNumber: "-",
                    name: "-"
                },
                originDestination: {
                    arrival: {
                        airportCode: "-",
                        airportName: "-",
                        countryID: "",
                        date: "-",
                        parentLocation: "",
                        terminalName: "-",
                        time: "-"
                    },
                    departure: {
                        airportCode: "-",
                        airportName: "-",
                        countryID: "",
                        date: "-",
                        parentLocation: "",
                        terminalName: "-",
                        time: "-"
                    }
                },
                segmentID: "-"
            }
        ]
    }
];
