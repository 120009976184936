import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'disableSelectSeatBySegment'
})
export class DisableSelectSeatBySegmentPipe implements PipeTransform {

  transform(segment: string, seats: any[]): boolean {
    
    let disableSeat = false;

    seats?.map(seat => {
      if (seat.segment === segment) {
        disableSeat = true;
      }
    });
    
    return disableSeat;
  }

}
