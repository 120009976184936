import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AmadeusParserToInput} from "../models/amadeus-parser-to-input.model";
import {OrderFromAmadeusParserService} from "../services/order-from-amadeus-parser.service";

@Component({
  selector: 'app-gds-details',
  templateUrl: './gds-details.component.html',
  styleUrls: ['./gds-details.component.scss']
})
export class GdsDetailsComponent implements OnInit, OnDestroy {

  @Output() emitGDSData = new EventEmitter();
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  inputValue = '';
  errorInStep = '';

  constructor(
    private orderFromAmadeusParserService: OrderFromAmadeusParserService
  ) { }

  ngOnInit() {
    this.orderFromAmadeusParserService.amadeusOrderParsedData$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(res => {
        this.goToFilter(res as AmadeusParserToInput);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  parseOrder() {
    this.errorInStep = '';
    if (this.inputValue.length) {
      this.orderFromAmadeusParserService.prepareArrayOfSegments(this.inputValue);
    }
  }

  goToFilter(data: AmadeusParserToInput) {
    if (!data.errorMsg && data.data.flightNumbers.length) {
      this.emitGDSData.emit(data.data);
    } else {
      this.showParseError(data.errorMsg ? data.errorMsg : '');
    }
  }

  showParseError(place = '') {
    this.errorInStep = place ? place : `Incorrect command for filter.`;
  }

}
