import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Corporate} from '../types/corporate';
import {ErrorResponse, MetaResponse} from '../types/system';

@Injectable({
  providedIn: 'root'
})

export class HubCorporateService {
  private subUrl = `${environment.ndcApiEndpointV2}agent/pms/corporates/rest/`;
  corporate;

  constructor(
    public http: HttpClient) {
  }


  getAll() {
    const url = this.subUrl;
    return this.http.get<HttpResponse<Corporate[]>>(url, this.options());
  }

  get(id: string) {
    const url = this.subUrl + id;

    return this.http.get<HttpResponse<Corporate>>(url, this.options());
  }

  create(model) {
    const url = this.subUrl;
    return this.http.post<HttpResponse<MetaResponse | ErrorResponse>>(url, model, this.options());
  }

  update(model) {
    const url = this.subUrl + model.id;

    return this.http.put<HttpResponse<MetaResponse | ErrorResponse>>(url, model, this.options());
  }

  delete(id) {
    const url = this.subUrl + id;
    return this.http.delete<HttpResponse<MetaResponse>>(url, this.options());
  }

  private options() {
    let options: any = {
      observe: 'response',
    };
    return options;
  }
}
