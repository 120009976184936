import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {AuthGuard} from "../shared/guards/auth.guard";
import {ShowFrontGuard} from "../shared/guards/show-front.guard";
import {CorporateListComponent} from './corporate-list/corporate-list.component';
import {CorporateComponent} from './corporate/corporate.component';
import {CorporateDetailComponent} from './corporate-detail/corporate-detail.component';
import {ProfileDetailComponent} from './profile-detail/profile-detail.component';
import {PendingChangesGuard} from "../shared/guards/pending-changes.guard";
import {KeycloakGuard} from "../shared/guards/keycloak-guard";

const routes: Routes = [
  {
    path: 'corporates',
    canActivate: [AuthGuard, ShowFrontGuard, KeycloakGuard],
    data: {
      route: 'corporates'
    },
    component: CorporateComponent,
    children: [
      {
        path: ':id',
        component: CorporateDetailComponent,
      },
      {
        path: ':corporate_id/travelers/:id',
        component: ProfileDetailComponent,
        canDeactivate: [PendingChangesGuard]
      },
      {
        path: '',
        component: CorporateListComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfilesRoutingModule {}
