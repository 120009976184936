import {Component, Input, OnInit} from '@angular/core';
import {ErrorAlert} from "../../models/error-alert";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'error-alert-block',
  templateUrl: './error-alert-block.component.html',
  styleUrls: ['./error-alert-block.component.scss']
})
export class ErrorAlertBlockComponent implements OnInit {
  @Input() error: ErrorAlert = new ErrorAlert();

  constructor() { }

  ngOnInit() {
  }
}
