import { Pipe, PipeTransform } from '@angular/core';
import { OfferService } from '../services/offer.service';

@Pipe({
  name: 'filterSimpleOffer'
})
export class FilterSimpleOfferPipe implements PipeTransform {

  constructor(
    private offerService: OfferService
  ) {}

  transform(offers: any[]): any[] {
    const simpleOffersModel = this.offerService.getSimpleModelByOffers(offers);

    return simpleOffersModel;
  }
}
