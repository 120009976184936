import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { take } from 'rxjs/operators';
import {PnrParserService} from "../services/pnr-parser.service";
import {Subject} from "rxjs";
import {Dictionary} from "../../shared/types/dictionary";
import {DashboardHelpersService} from "../services/dashboard-helpers.service";
import {HelpersService} from "../../shared/services/helpers.service";
import {FLIGHT_TYPE} from "../../shared/constants";
import moment from 'moment';

@Component({
  selector: 'app-pnr-search',
  templateUrl: './pnr-search.component.html',
  styleUrls: ['./pnr-search.component.scss']
})
export class PnrSearchComponent implements OnChanges, OnInit, OnDestroy {

  @Input() allowNewSearch;
  @Input() pnrText;
  @Output() emitParsedData = new EventEmitter();
  @Output() emitAbortData = new EventEmitter();
  @Output() emitPnrText = new EventEmitter();
  @Output() emitOpenOfferHistoryModal = new EventEmitter();
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  inputValue = ``;
  isShowParseButton = false;

  excludedFlightTypeOptions = [];
  passTypes = ['CHD', 'ADT', 'INF', 'YAD'];
  flightTypeOptions = {};
  flightType = { type: null, showDrops: true };
  payload = {
    travelers: null,
    originDestinations: [],
    connections: [],
    flights: [],
    type: '',
  };
  parsedInitialData;
  arrivalAirPort = null;
  departureAirPort = null;
  showErrorMatchAirports;
  currentNumberOfCities = 0;
  allSegmentRanges = [];
  segmentRangesMoreOneDay = [];
  numberOfCities = [];
  selectedODs = [];
  maxRangeItem = null;
  FLIGHT_TYPE = FLIGHT_TYPE;

  constructor(
    private pnrParserService: PnrParserService,
    public helpersService: HelpersService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pnrText && changes.pnrText.currentValue) {
      this.inputValue = changes.pnrText.currentValue;
      if (this.inputValue.length > 1) {
        this.parsedInitialData = this.pnrParserService.startPnrParser(this.inputValue);
        this.tryAutodetectFlightType();
      }
    }
  }

  ngOnInit() {
    this.flightTypeOptions[this.FLIGHT_TYPE.RT] = 'Round-Trip';
    this.flightTypeOptions[this.FLIGHT_TYPE.OW] = 'One-Way';
    this.flightTypeOptions[this.FLIGHT_TYPE.MC] = 'Multi-City';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  onTextAreaInput(event) {
    if (event && event.inputType && event.inputType === 'insertFromPaste') {
      this.parseOrder();
    } else {
      this.isShowParseButton = true;
    }
  }

  parseOrder() {
    this.isShowParseButton = false;
    this.onAbort();
    this.selectedODs = [];
    this.payload = {
      travelers: null,
        originDestinations: [],
        connections: [],
        flights: [],
        type: '',
    };
    this.flightType = { type: null, showDrops: true };
    if (this.inputValue.length) {
      this.parsedInitialData = this.pnrParserService.startPnrParser(this.inputValue);
      this.tryAutodetectFlightType();
    }
  }

  changePassengerType(index, value) {
    this.parsedInitialData.passengers[index].type = value;
  }

  onSelectNumberOfCities(number = 0) {
    if (number) {
      this.currentNumberOfCities = number;
    }
    const maxRangesBySelectedNum = [...this.allSegmentRanges].sort((a, b) => (a.timeRangeInMilliseconds > b.timeRangeInMilliseconds) ? -1 : 1).slice(0, number - 1);
    const sortedRangesByFirstSeg = [...maxRangesBySelectedNum].sort((a, b) => (a.firstSeg > b.firstSeg) ? 1 : -1);
    this.selectedODs = this.extractAirportsForSelectMC(sortedRangesByFirstSeg);
    this.onChangeMCRoute(this.selectedODs);
    this.addDepDateToSelectedODs(this.selectedODs);
  }

  extractAirportsForSelectMC(ranges) {
    const result = [];
    const minMaxRes = [];
    let ODCandidates = this.selectedODs;
    let minIdxForGetAirports = 0;
    let maxIdxForGetAirports = this.parsedInitialData.segments.length + 1;

    if (!this.selectedODs.length) {
      ODCandidates = [];
      ranges.map((range, idx) => {
        const airPorts = {
          departureAirPorts: {},
          arrivalSelected: { segIdx: range.firstSeg , value: this.parsedInitialData.segments[range.firstSeg].arrivalAirPort}
        };
        airPorts.departureAirPorts = idx === 0 ? { segIdx: 0, value: this.parsedInitialData.segments[0].departureAirPort} : { segIdx: ranges[idx - 1].secondSeg, value: this.parsedInitialData.segments[ranges[idx - 1].secondSeg].departureAirPort};
        ODCandidates.push(airPorts);
        if (idx === ranges.length - 1) {
          const lastAirPorts = {
            departureAirPorts: {},
            arrivalSelected: { segIdx: this.parsedInitialData.segments.length - 1, value: this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort},
          };
          lastAirPorts.departureAirPorts = { segIdx: range.secondSeg, value: this.parsedInitialData.segments[range.secondSeg].departureAirPort};
          ODCandidates.push(lastAirPorts);
        }
      });
    }

    ranges.map((range, idx) => {
      const airPorts = {
        departureAirPorts: {segIdx: -1, value: ''},
        arrivalAirPorts: [],
        arrivalSelected: { segIdx: range.firstSeg , value: this.parsedInitialData.segments[range.firstSeg].arrivalAirPort }
      };
      airPorts.departureAirPorts = idx === 0 ? { segIdx: 0, value: this.parsedInitialData.segments[0].departureAirPort} : { segIdx: ranges[idx - 1].secondSeg, value: this.parsedInitialData.segments[ranges[idx - 1].secondSeg].departureAirPort};

      airPorts.arrivalAirPorts = this.parsedInitialData.segments.map((segment, i) => {
        return {
          segIdx: i,
          value: segment.arrivalAirPort,
        };
      });
      result.push(airPorts);


      if (idx === ranges.length - 1) {
        const lastAirPorts = {
          departureAirPorts: {},
          arrivalAirPorts: [],
          arrivalSelected: { segIdx: this.parsedInitialData.segments.length - 1, value: this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort},
        };
        lastAirPorts.departureAirPorts = { segIdx: range.secondSeg, value: this.parsedInitialData.segments[range.secondSeg].departureAirPort};
        lastAirPorts.arrivalAirPorts[0] = { segIdx: this.parsedInitialData.segments.length - 1, value: this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort};
        result.push(lastAirPorts);
      }
    });

    result.map((item, i) => {
      minIdxForGetAirports = item.departureAirPorts.segIdx;
      maxIdxForGetAirports = this.parsedInitialData.segments.length - (this.currentNumberOfCities - (i + 1));
      minMaxRes.push({
        min: minIdxForGetAirports,
        max: maxIdxForGetAirports
      });
    });

    result.map((res, index) => {
      result[index].arrivalAirPorts = res.arrivalAirPorts.slice(minMaxRes[index].min, minMaxRes[index].max);
    });
    return result;
  }

  extractAirportsForSelectRT(arrival = null) {
    this.payload.connections = [];
    this.payload.flights = [];

    const airPorts = {
      departureAirPorts: {},
      arrivalAirPorts: [],
      arrivalSelected: arrival ? arrival : null
    };

    const lastAirPortArrival = {...arrival};
    lastAirPortArrival.segIdx += 1;
    lastAirPortArrival.value = this.parsedInitialData.segments[lastAirPortArrival.segIdx].departureAirPort;

    const lastAirPort = {
      departureAirPorts: lastAirPortArrival ? lastAirPortArrival : null,
      arrivalAirPorts: [],
      arrivalSelected: { segIdx: this.parsedInitialData.segments.length - 1, value: this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort }
    };

    if (this.flightType.type === 'RT') {
      airPorts.departureAirPorts = { segIdx: 0, value: this.parsedInitialData.segments[0].departureAirPort };
      airPorts.arrivalAirPorts = this.parsedInitialData.segments
        .map((segment, i) => {
          return {
            segIdx: i,
            value: segment.arrivalAirPort
          };
        })
        .filter(airPort => airPort.value !== airPorts.departureAirPorts['value']);
    }

    [airPorts, lastAirPort].map((segment, index) => {
      let lastSeg = segment.arrivalSelected.segIdx;
      let firstSeg = segment.departureAirPorts.segIdx;
      this.payload.flights.push(this.parsedInitialData.segments.slice(firstSeg, lastSeg + 1));
      const odWithSegments = [
        {segments: this.parsedInitialData.segments.slice(0, arrival.segIdx + 1)},
        {segments: this.parsedInitialData.segments.slice(arrival.segIdx + 1)}
      ];
      this.payload.originDestinations = [];
      odWithSegments.map((od, idx) => {
        const firstSeg = od.segments[0];
        const lastSeg = od.segments[od.segments.length - 1];
        this.payload.originDestinations.push(
          {
            departure: {
              airportCode: firstSeg.departureAirPort,
              date: `${firstSeg.dateInFormat.year}-${this.helpersService.addZero(firstSeg.dateInFormat.month)}-${this.helpersService.addZero(firstSeg.dateInFormat.day)}`,
              time: "",
              terminalName: "",
            },
            arrival: {
              airportCode: lastSeg.arrivalAirPort,
              time: "",
              terminalName: "",
            }
          }
        );
      });

    });

    return [airPorts, lastAirPort];
  }

  extractAirportsForSelectOW() {
    return [{
      departureAirPorts: { segIdx: 0, value: this.parsedInitialData.segments[0].departureAirPort },
      arrivalAirPorts: [],
      arrivalSelected: { segIdx: this.parsedInitialData.segments.length - 1, value: this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort }
    }];
  }


  setFlightType(type) {
    this.selectedODs = [];
    this.flightType.type = type;
    this.payload = {
      travelers: null,
      originDestinations: [],
      connections: [],
      flights: [],
      type: '',
    };
    this.currentNumberOfCities = null;
    switch (this.flightType.type) {
      case this.FLIGHT_TYPE.RT:
        this.payload.type = 'RT';
        return this.onRoundTripType();
      case 'OW':
        this.payload.type = 'OW';
        return this.onOneWayType();
      case this.FLIGHT_TYPE.MC:
        this.payload.type = this.FLIGHT_TYPE.MC;
        this.numberOfCities = this.getArrOfCityNumbers(2, this.parsedInitialData.segments.length);
        return ;
    }
  }

  private extractConnections(odSegments) {
    const connections = [];
    if (!(!odSegments.length || odSegments.length === 1)) {
      odSegments.map((segment, index) => {
        if (index) {
          connections.push(segment.departureAirPort);
        }
      });
    }
    return connections;
  }


  private onChangeMCRoute(path) {
     this.payload.originDestinations = [];
     this.payload.connections = [];
     this.payload.flights = [];
     path.map(item => {
        const depSeg = this.parsedInitialData.segments[item.departureAirPorts.segIdx];
        let lastSeg = item.arrivalSelected.segIdx;
        this.payload.flights.push(this.parsedInitialData.segments.slice(item.departureAirPorts.segIdx, lastSeg + 1));
        this.payload.originDestinations.push({
           departure: {
              airportCode: item.departureAirPorts.value,
              date: `${depSeg.dateInFormat.year}-${this.helpersService.addZero(depSeg.dateInFormat.month)}-${this.helpersService.addZero(depSeg.dateInFormat.day)}`,
              time: "",
              terminalName: "",
           },
           arrival: {
              airportCode: item.arrivalSelected.value,
              time: "",
              terminalName: "",
           }
         });
     });
  }

  private onSelectRTFirstOD(path) {
    const firstODArrivalIdx = this.parsedInitialData.segments.findIndex(segment => segment.arrivalAirPort === path.arrival);
      if (!this.parsedInitialData.segments[firstODArrivalIdx + 1] || firstODArrivalIdx === - 1 || !this.parsedInitialData.segments[firstODArrivalIdx]) {
      return;
    }
    const odWithSegments = [
      {segments: this.parsedInitialData.segments.slice(0, firstODArrivalIdx + 1)},
      {segments: this.parsedInitialData.segments.slice(firstODArrivalIdx + 1)}
    ];
    this.payload.connections = [];
    this.payload.originDestinations = [];
    odWithSegments.map((od, idx) => {
      this.payload.flights[idx] = od.segments;
      const firstSeg = od.segments[0];
      const lastSeg = od.segments[od.segments.length - 1];
      this.payload.originDestinations.push(
        {
          departure: {
            airportCode: firstSeg.departureAirPort,
            date: `${firstSeg.dateInFormat.year}-${this.helpersService.addZero(firstSeg.dateInFormat.month)}-${this.helpersService.addZero(firstSeg.dateInFormat.day)}`,
            time: "",
            terminalName: "",
          },
          arrival: {
            airportCode: lastSeg.arrivalAirPort,
            time: "",
            terminalName: "",
          }
        }
      );
    });
  }

  setSelectedAirports(path) {
    if (this.flightType.type === 'RT' && path) {
      this.onSelectRTFirstOD(path);
    }

    if (this.flightType.type === this.FLIGHT_TYPE.MC) {
      this.selectedODs = [];
      let lastSeg = -1;
      let flightChangedIdx = -1;

        path.map((item, i) => {
          if (item.isChange) {
            lastSeg = item.arrivalSelected.segIdx;
            flightChangedIdx = i;
          }
        });

      if (lastSeg !== -1 && flightChangedIdx !== -1) {
        const noChangeODs = path.slice(0, flightChangedIdx + 1);
        const startIdxRange = this.allSegmentRanges.findIndex(item => item.firstSeg === lastSeg);
        const maxRangesBySelectedNum = [...this.allSegmentRanges.slice(startIdxRange + 1)].sort((a, b) => (a.timeRangeInMilliseconds > b.timeRangeInMilliseconds) ? -1 : 1).slice(0, this.currentNumberOfCities - (flightChangedIdx + 2));
        const sortedRangesByFirstSeg = [...maxRangesBySelectedNum].sort((a, b) => (a.firstSeg > b.firstSeg) ? 1 : -1);
        let result = [];
        noChangeODs.map((item, i) => {
          result.push(this.allSegmentRanges.find(range => range.firstSeg === item.arrivalSelected.segIdx));
        });
        result = result.concat(sortedRangesByFirstSeg);
        this.selectedODs = this.extractAirportsForSelectMC(result);
      }

      this.onChangeMCRoute(this.selectedODs);
    }
    this.checkChangingDestinations(this.payload.originDestinations);

    if (this.flightType.type === 'RT') {
      this.selectedODs = this.extractAirportsForSelectRT({segIdx: this.selectedODs[0].arrivalSelected.segIdx, value: this.selectedODs[0].arrivalSelected.value});
    }

    this.addDepDateToSelectedODs(this.selectedODs);
    this.addFlightNumberToSelectedODs(this.selectedODs);
  }

  private addDepDateToSelectedODs(selectedODs: Array<any>) {
    this.selectedODs = selectedODs.map(direction => {
      const segIdx = direction.departureAirPorts.segIdx;
      direction.departureDate = this.parsedInitialData.segments[segIdx].dateInFormat;
      return direction;
    });
  }

  private addFlightNumberToSelectedODs(selectedODs: Array<any>) {
    this.selectedODs = selectedODs.map(direction => {
      const segIdx = direction.departureAirPorts.segIdx;
      direction.flightNumber = this.parsedInitialData.segments[segIdx].airline + this.parsedInitialData.segments[segIdx].flightNumber;
      return direction;
    });
  }

  private onRoundTripType() {
    this.selectedODs = [];
    this.selectedODs = this.extractAirportsForSelectRT({segIdx: this.maxRangeItem.firstSeg, value: this.parsedInitialData.segments[this.maxRangeItem.firstSeg].arrivalAirPort});
    this.addDepDateToSelectedODs(this.selectedODs);
    this.addFlightNumberToSelectedODs(this.selectedODs);
    this.checkChangingDestinations(this.payload.originDestinations);
  }

  private tryAutodetectFlightType() {
    if (!this.parsedInitialData.segments || this.parsedInitialData.segments && !this.parsedInitialData.segments.length) { return; }

    const tempSegValues = [];
    const allSegments = this.parsedInitialData.segments;
    for (let i = 0; i < allSegments.length; i++) {
      const first = allSegments[i];
      const next = allSegments[i + 1];
      if (first && next) {
        const firstUnix = new Date(`${first.dateInFormat['month']}/${first.dateInFormat['day']}/${first.dateInFormat['year']} ${first.departureTime}:00`).getTime();
        const nextUnix = new Date(`${next.dateInFormat['month']}/${next.dateInFormat['day']}/${next.dateInFormat['year']} ${next.departureTime}:00`).getTime();
        tempSegValues.push({
          firstSeg: i,
          secondSeg: i + 1,
          timeRangeInMilliseconds: nextUnix - firstUnix,
          timeRangeInFormat: moment.duration(nextUnix - firstUnix),
        });
      }
    }

    let maxRange = Math.max(...tempSegValues.map(s => s.timeRangeInMilliseconds));

    const flightCandidates = [];
    tempSegValues.map(item => {
      if (item.timeRangeInMilliseconds === maxRange) {
        this.maxRangeItem = item;
      }
      if (item.timeRangeInMilliseconds > 86400000) {
        this.parsedInitialData.segments[item.firstSeg]['isBreak'] = true;
        flightCandidates.push(item);
      } else {
        this.parsedInitialData.segments[item.firstSeg]['isBreak'] = false;
      }
    });

    this.allSegmentRanges = tempSegValues;
    this.segmentRangesMoreOneDay = flightCandidates;

    // try detect MC
    if (
      this.parsedInitialData.segments.length > 1
      && this.parsedInitialData.segments[0].departureAirPort !== this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort
      && flightCandidates.length
    ) {
      this.setFlightType(this.FLIGHT_TYPE.MC);
      this.excludedFlightTypeOptions = [this.FLIGHT_TYPE.RT];
      this.numberOfCities = this.getArrOfCityNumbers(2, this.parsedInitialData.segments.length);
      if (this.numberOfCities.length === 1) {
        this.onSelectNumberOfCities(this.numberOfCities[0]);
      }
      // this.currentNumberOfCities = flightCandidates.length + 1;
    }

    if ( this.parsedInitialData.segments.length >= 1
      && this.parsedInitialData.segments[0].departureAirPort !== this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort
      && !this.segmentRangesMoreOneDay.length) {

      if (this.parsedInitialData.segments.length === 1) {
        this.excludedFlightTypeOptions = [this.FLIGHT_TYPE.RT, this.FLIGHT_TYPE.MC];
      } else if (this.parsedInitialData.segments.length > 1) {
        this.excludedFlightTypeOptions = [this.FLIGHT_TYPE.RT];
      }
      this.setFlightType(this.FLIGHT_TYPE.OW);
      return;
    }

    if (
      allSegments.length >= 1
      && this.maxRangeItem
      && this.parsedInitialData.segments[0].departureAirPort === this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort
    ) {
      this.excludedFlightTypeOptions = [this.FLIGHT_TYPE.OW];
      this.flightType.type = FLIGHT_TYPE.RT;
      this.payload.originDestinations = [];
      this.selectedODs = this.extractAirportsForSelectRT({segIdx: this.maxRangeItem.firstSeg, value: this.parsedInitialData.segments[this.maxRangeItem.firstSeg].arrivalAirPort});
      let path = {
        departure: {segIdx: 0, value: this.parsedInitialData.segments[0].departureAirPort},
        arrival: {segIdx: this.maxRangeItem.firstSeg, value: this.parsedInitialData.segments[this.maxRangeItem.firstSeg].arrivalAirPort},
        arrivalSelected: {segIdx: this.maxRangeItem.firstSeg, value: this.parsedInitialData.segments[this.maxRangeItem.firstSeg].arrivalAirPort},
      };
      this.setSelectedAirports(path);
      this.setFlightType(this.FLIGHT_TYPE.RT);
    }
  }

  private onOneWayType() {
    this.selectedODs = [];
    this.payload.originDestinations = [];
    if (this.parsedInitialData.segments.length) {
      const firstSeg = this.parsedInitialData.segments[0];
      this.payload.flights[0] = this.parsedInitialData.segments;
      this.payload.originDestinations.push({
        departure: {
          airportCode: firstSeg.departureAirPort,
          date: `${firstSeg.dateInFormat.year}-${this.helpersService.addZero(firstSeg.dateInFormat.month)}-${this.helpersService.addZero(firstSeg.dateInFormat.day)}`,
          time: "",
          terminalName: "",
        },
        arrival: {
          airportCode: this.parsedInitialData.segments[this.parsedInitialData.segments.length - 1].arrivalAirPort,
          time: "",
          terminalName: "",
        }
      });
    }
    this.selectedODs = this.extractAirportsForSelectOW();
    this.addDepDateToSelectedODs(this.selectedODs);
    this.checkChangingDestinations(this.payload.originDestinations);
  }


  passengersToTravelers() {
    let convertedPassengers = {
      chd: 0,
      adt: 0,
      inf: 0,
      yad: 0,
    };

    this.parsedInitialData.passengers.forEach(passenger => {
      convertedPassengers[passenger.type.toLowerCase()]++;
    });

    this.payload['travelers'] = convertedPassengers;
  }

  onSearch() {
    this.passengersToTravelers();
    this.parsedInitialData['payload'] = this.payload;
    this.emitParsedData.emit(this.parsedInitialData);
    this.emitPnrText.emit(this.inputValue);
  }


  getTravelerTitle(code) {
    switch (code) {
      case 'ADT':
        return 'Adult';
      case 'CHD':
        return 'Child';
      case 'INF':
        return 'Infant';
      case 'YAD':
        return 'Young Adult';
    }
  }

  onAbort() {
    this.emitAbortData.emit(null);
  }

  checkChangingDestinations(destinations) {
    if (!destinations.length) {
      this.showErrorMatchAirports = undefined;
      return false;
    }
    destinations.every((destination, index) => {
      if (destination.departure.airportCode === destination.arrival.airportCode) {
        this.showErrorMatchAirports = index;
        return false;
      } else {
        this.showErrorMatchAirports = undefined;
        return true;
      }
    });
  }

  private getArrOfCityNumbers(minValue: number, maxValue: number): number[] {
    if (!minValue && !maxValue && minValue !== 0) {
      return [];
    }
    const result = [];
    for (let i = minValue; i <= maxValue; i++) {
      result.push(i);
    }
    return result;
  }

  openOfferHistoryModal() {
    this.emitOpenOfferHistoryModal.emit(true);
  }

}
